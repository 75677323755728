import * as React from 'react'
import Helmet from 'react-helmet'
import { navigate, RouteComponentProps } from '@reach/router'
import { parse as parseQueryString } from 'query-string'
import { useAuthInfo } from '../AuthContext'

import './login.css'

interface Props extends RouteComponentProps {}

type State = {
  message: string | null
}

type LoginUser = { id: string; isSuperUser: boolean; username: string }

async function realLogin(
  username: string,
  password: string
): Promise<LoginUser> {
  try {
    const response = await fetch('/api/login', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    })

    const json: { user: LoginUser } = await response.json()
    console.log('login', { response, json })

    if (!response.ok) {
      const error: any = new Error('Invalid username/password')
      error.response = response
      error.body = json
      throw error
    }

    return Promise.resolve(json.user)
  } catch (err) {
    return Promise.reject(err)
  }
}

class LoginPage extends React.Component<Props & { setUser: any }, State> {
  state: State = { message: null }

  handleSubmit = async (e: React.SyntheticEvent<any>) => {
    const { location } = this.props

    e.preventDefault()
    const username = e.currentTarget.email.value
    const password = e.currentTarget.password.value

    try {
      const result = await realLogin(username, password)

      await this.props.setUser({ ...result, authenticated: true })

      const query = parseQueryString(location ? location.search : '')
      const redirect =
        query.redirect && typeof query.redirect === 'string'
          ? query.redirect
          : '/dashboard'

      return navigate(redirect)
    } catch (err) {
      this.setState({ message: err.message })
    }
  }

  render() {
    return (
      <div className="ui middle aligned center aligned grid">
        <Helmet>
          <body className="login" />
        </Helmet>

        <div className="column">
          <h2 className="ui inverted icon header">
            <img src={require('./gouda_hero.png')} alt="" className="image" />
            <div className="content">Log-in to GoudaCMS</div>
          </h2>
          <form onSubmit={this.handleSubmit} className="ui large form">
            <div className="ui stacked segment">
              <div className="field">
                <div className="ui left icon input">
                  <i className="user icon" />
                  <input
                    type="text"
                    name="email"
                    placeholder="E-mail address"
                    autoCorrect="off"
                    autoCapitalize="none"
                  />
                </div>
              </div>
              <div className="field">
                <div className="ui left icon input">
                  <i className="lock icon" />
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                  />
                </div>
              </div>
              <button
                type="submit"
                className="ui fluid large violet submit button"
              >
                Login
              </button>
            </div>
          </form>

          {this.state.message && (
            <div className="ui error message">{this.state.message}</div>
          )}
        </div>
      </div>
    )
  }
}

interface Props extends RouteComponentProps {}

export default function LoginPageWrapper(props: Props) {
  const auth = useAuthInfo()
  return <LoginPage {...props} setUser={auth.setUser} />
}
