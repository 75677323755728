import React, { useRef, FormEvent } from 'react'
import { RouteComponentProps } from '@reach/router'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const UPLOAD_FILE_MUTATION = gql`
  mutation UploadFile($file: Upload!) {
    singleUpload(file: $file) {
      filename
      mimetype
      encoding
    }
  }
`

interface Props extends RouteComponentProps<{ site: string }> {}

export default function Playground(_props: Props) {
  return (
    <div>
      <div>Hello</div>
      <UploadForm />
    </div>
  )
}

const UploadForm = () => {
  const [uploadFile] = useMutation(UPLOAD_FILE_MUTATION)
  const fileInput = useRef<HTMLInputElement>(null)
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if (!fileInput.current) {
      return
    }

    const { files } = fileInput.current
    console.log('files', files)
    if (!files || files.length < 1) {
      return
    }

    const r = await uploadFile({
      variables: {
        file: files[0],
      },
    })
    console.log('upload result', r)
  }

  return (
    <form className="ui form" onSubmit={handleSubmit}>
      <label>
        Image:
        <input type="file" ref={fileInput} />
      </label>

      <button type="submit">Upload File</button>
    </form>
  )
}
