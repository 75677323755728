import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  JSONObject: { [key: string]: any },
  /** RFC 3339 compliant datetime; ex: 2007-12-03T10:15:30Z */
  DateTime: Date,
  JSON: any,
  Upload: any,
  /** RFC 3339 compliant date; ex:  2007-12-03 */
  Date: Date,
  /** RFC 3339 compliant time; ex:  10:15:30Z */
  Time: Date,
};

export type BasicFile = {
   __typename?: 'BasicFile',
  url: Scalars['String'],
};

export type BlogPost = {
   __typename?: 'BlogPost',
  id: Scalars['ID'],
  slug: Scalars['String'],
  url: Scalars['String'],
  siteId: Scalars['ID'],
  author?: Maybe<BlogPostAuthor>,
  title: Scalars['String'],
  content: Scalars['String'],
  tags: Array<Scalars['String']>,
  heroImage?: Maybe<Scalars['String']>,
  isPublished: Scalars['Boolean'],
  publishedAt?: Maybe<Scalars['DateTime']>,
  seo?: Maybe<SeoMetadata>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type BlogPostAuthor = {
   __typename?: 'BlogPostAuthor',
  id: Scalars['ID'],
  name: Scalars['String'],
  email: Scalars['String'],
  photo: Scalars['String'],
  title: Scalars['String'],
  url?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type BlogPostsConnection = {
   __typename?: 'BlogPostsConnection',
  totalCount: Scalars['Int'],
  edges: Array<BlogPostsEdge>,
  pageInfo: PageInfo,
};

export type BlogPostsEdge = {
   __typename?: 'BlogPostsEdge',
  node: BlogPost,
};

export type CommerceSettings = {
   __typename?: 'CommerceSettings',
  tableRate: Array<TableRateSettings>,
};

export type CommerceSettingsInput = {
  tableRate: Array<TableRateSettingsInput>,
};

export type CreateBlogAuthorInput = {
  name: Scalars['String'],
  email: Scalars['String'],
  photo: Scalars['String'],
  title: Scalars['String'],
  url?: Maybe<Scalars['String']>,
};

export type CreateBlogAuthorPayload = {
   __typename?: 'CreateBlogAuthorPayload',
  blogAuthor: BlogPostAuthor,
};

export type CreateBlogPostInput = {
  authorId?: Maybe<Scalars['ID']>,
  slug: Scalars['String'],
  title: Scalars['String'],
  content: Scalars['String'],
  tags: Array<Scalars['String']>,
  heroImage?: Maybe<Scalars['String']>,
  isPublished?: Maybe<Scalars['Boolean']>,
  seo?: Maybe<SeoMetadataInput>,
};

export type CreateBlogPostPayload = {
   __typename?: 'CreateBlogPostPayload',
  blogPost: BlogPost,
};

export type CreateFoodMenuInput = {
  name: Scalars['String'],
  sections: Array<Maybe<FoodMenuSectionInput>>,
};

export type CreateFoodMenuPayload = {
   __typename?: 'CreateFoodMenuPayload',
  foodMenu: FoodMenu,
};

export type CreateFormInput = {
  name: Scalars['String'],
  fields: Scalars['JSON'],
  notify: Array<Scalars['String']>,
  followUp?: Maybe<Scalars['String']>,
  buttonText?: Maybe<Scalars['String']>,
};

export type CreateFormPayload = {
   __typename?: 'CreateFormPayload',
  form: Form,
};

export type CreateMenuInput = {
  name: Scalars['String'],
  items: Array<MenuItemInput>,
};

export type CreateMenuPayload = {
   __typename?: 'CreateMenuPayload',
  errors: Array<Error>,
  menu: Menu,
};

export type CreatePageInput = {
  name: Scalars['String'],
  url: Scalars['String'],
  content: Array<Scalars['JSONObject']>,
  seo: PageSeoInput,
  enabled: Scalars['Boolean'],
};

export type CreateProductInput = {
  name: Scalars['String'],
  slug: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  shippingType: ShippingType,
  enabled: Scalars['Boolean'],
  taxable: Scalars['Boolean'],
  seo?: Maybe<SeoMetadataInput>,
  image?: Maybe<Scalars['String']>,
  images: Array<Scalars['String']>,
  price?: Maybe<Scalars['Int']>,
  attributes: Array<ProductAttributeInput>,
};

export type CreateProductPayload = {
   __typename?: 'CreateProductPayload',
  product: Product,
};

export type CreateSiteInput = {
  code: Scalars['String'],
  domain: Scalars['String'],
  name: Scalars['String'],
  otherDomains?: Maybe<Array<Scalars['String']>>,
};

export type CreateUserInput = {
  username: Scalars['String'],
  password: Scalars['String'],
  sites: Array<Scalars['ID']>,
};

export type CreateUserPayload = {
   __typename?: 'CreateUserPayload',
  user?: Maybe<User>,
};

export type Customer = {
   __typename?: 'Customer',
  id: Scalars['ID'],
  siteId: Scalars['ID'],
  name: Scalars['String'],
  email: Scalars['String'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type DailySubmissionStat = {
   __typename?: 'DailySubmissionStat',
  date: Scalars['String'],
  total: Scalars['Int'],
};



export type DeleteBlogPostPayload = {
   __typename?: 'DeleteBlogPostPayload',
  site: Scalars['ID'],
  post: Scalars['ID'],
};

export type DeleteFoodMenuPayload = {
   __typename?: 'DeleteFoodMenuPayload',
  site: Scalars['ID'],
  menu: Scalars['ID'],
};

export type DeleteFormPayload = {
   __typename?: 'DeleteFormPayload',
  id: Scalars['ID'],
};

export type DeleteMenuPayload = {
   __typename?: 'DeleteMenuPayload',
  site: Scalars['ID'],
  menu: Scalars['ID'],
};

export type DeleteProductPayload = {
   __typename?: 'DeleteProductPayload',
  site: Scalars['ID'],
  product: Scalars['ID'],
};

export type DeleteRedirectPayload = {
   __typename?: 'DeleteRedirectPayload',
  site: Scalars['ID'],
  redirect: Scalars['ID'],
};

export type DisableProductPayload = {
   __typename?: 'DisableProductPayload',
  product: Product,
};

export type EnableProductPayload = {
   __typename?: 'EnableProductPayload',
  product: Product,
};

export type Error = {
   __typename?: 'Error',
  key: Scalars['String'],
  message: Scalars['String'],
};

export type Event = {
   __typename?: 'Event',
  id: Scalars['ID'],
  siteId: Scalars['ID'],
  title: Scalars['String'],
  description: Scalars['String'],
};

export type EventConnection = {
   __typename?: 'EventConnection',
  totalCount: Scalars['Int'],
  edges: Array<EventEdge>,
  pageInfo: PageInfo,
};

export type EventEdge = {
   __typename?: 'EventEdge',
  node: Event,
};

export type File = {
   __typename?: 'File',
  filename: Scalars['String'],
  mimetype: Scalars['String'],
  encoding: Scalars['String'],
};

export type FoodMenu = {
   __typename?: 'FoodMenu',
  id: Scalars['ID'],
  name: Scalars['String'],
  sections: Array<FoodMenuSection>,
};

export type FoodMenuItem = {
   __typename?: 'FoodMenuItem',
  id: Scalars['ID'],
  title: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  photo?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['String']>,
  images: FoodMenuItemImages,
  additional: Array<FoodMenuItemMeta>,
};

export type FoodMenuItemImages = {
   __typename?: 'FoodMenuItemImages',
  thumbnail?: Maybe<Scalars['String']>,
};

export type FoodMenuItemMeta = {
   __typename?: 'FoodMenuItemMeta',
  label: Scalars['String'],
  value: Scalars['String'],
};

export type FoodMenuSection = {
   __typename?: 'FoodMenuSection',
  id: Scalars['ID'],
  title: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  items: Array<FoodMenuItem>,
};

export type FoodMenuSectionInput = {
  id: Scalars['ID'],
  title: Scalars['String'],
  description: Scalars['String'],
  items?: Maybe<Scalars['JSON']>,
};

export type Form = {
   __typename?: 'Form',
  id: Scalars['ID'],
  siteId: Scalars['ID'],
  name: Scalars['String'],
  fields: Scalars['JSON'],
  notify: Scalars['JSON'],
  followUp?: Maybe<Scalars['String']>,
  submissionCount: Scalars['Int'],
  submissionsPerDay: Array<DailySubmissionStat>,
  buttonText?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type FormSubmission = {
   __typename?: 'FormSubmission',
  id: Scalars['ID'],
  formId: Scalars['ID'],
  siteId: Scalars['ID'],
  form: Form,
  submitter: FormSubmitterInfo,
  teaser: Scalars['String'],
  labels: Scalars['JSON'],
  values: Scalars['JSON'],
  isSpam: Scalars['Boolean'],
  spamAt?: Maybe<Scalars['DateTime']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};


export type FormSubmissionTeaserArgs = {
  maxLength?: Maybe<Scalars['Int']>
};

export type FormSubmitterInfo = {
   __typename?: 'FormSubmitterInfo',
  initials: Scalars['String'],
};

export type ImageAsset = {
   __typename?: 'ImageAsset',
  id: Scalars['ID'],
  name: Scalars['String'],
  size: Scalars['Int'],
  dimensions: ImageDimensions,
  url: Scalars['String'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type ImageDimensions = {
   __typename?: 'ImageDimensions',
  width: Scalars['Int'],
  height: Scalars['Int'],
};

export type ImageUpload = {
   __typename?: 'ImageUpload',
  url: Scalars['String'],
};



export type Menu = {
   __typename?: 'Menu',
  id: Scalars['ID'],
  name: Scalars['String'],
  items: Array<MenuItem>,
};

export type MenuItem = {
   __typename?: 'MenuItem',
  id: Scalars['ID'],
  label: Scalars['String'],
  url?: Maybe<Scalars['String']>,
  children: Array<MenuItem>,
};

export type MenuItemInput = {
  id: Scalars['ID'],
  label: Scalars['String'],
  url?: Maybe<Scalars['String']>,
  children: Array<MenuItemInput>,
};

export type Mutation = {
   __typename?: 'Mutation',
  createSite: Site,
  updateSite: Site,
  saveSiteSettings: Site,
  singleUpload: File,
  uploadImage: ImageUpload,
  createBlogAuthor: CreateBlogAuthorPayload,
  updateBlogAuthor: UpdateBlogAuthorPayload,
  createBlogPost: CreateBlogPostPayload,
  updateBlogPost: UpdateBlogPostPayload,
  deleteBlogPost: DeleteBlogPostPayload,
  createProduct: CreateProductPayload,
  updateProduct: UpdateProductPayload,
  deleteProduct: DeleteProductPayload,
  enableProduct: EnableProductPayload,
  disableProduct: DisableProductPayload,
  uploadProductImage: BasicFile,
  saveCommerceSettings: CommerceSettings,
  updateFoodItem?: Maybe<UpdateFoodItemPayload>,
  createFoodMenu: CreateFoodMenuPayload,
  updateFoodMenu: UpdateFoodMenuPayload,
  uploadFoodItemThumbnail: BasicFile,
  uploadFoodItemPhoto: BasicFile,
  deleteFoodMenu: DeleteFoodMenuPayload,
  saveThemeFile?: Maybe<SaveThemeFilePayload>,
  createUser: CreateUserPayload,
  updateUser: UpdateUserPayload,
  createRedirect?: Maybe<SaveRedirectPayload>,
  saveRedirect?: Maybe<SaveRedirectPayload>,
  deleteRedirect: DeleteRedirectPayload,
  createMenu: CreateMenuPayload,
  saveMenu: SaveMenuPayload,
  deleteMenu: DeleteMenuPayload,
  createPage: UpdatePagePayload,
  updatePage: UpdatePagePayload,
  removePage: RemovePagePayload,
  enablePage: Page,
  disablePage: Page,
  createForm: CreateFormPayload,
  saveForm: SaveFormPayload,
  deleteForm: DeleteFormPayload,
  markFormsAsSpam: Array<FormSubmission>,
  markFormsAsNotSpam: Array<FormSubmission>,
};


export type MutationCreateSiteArgs = {
  input: CreateSiteInput
};


export type MutationUpdateSiteArgs = {
  id: Scalars['ID'],
  input: UpdateSiteInput
};


export type MutationSaveSiteSettingsArgs = {
  site: Scalars['ID'],
  input: SiteSettingsInput
};


export type MutationSingleUploadArgs = {
  file: Scalars['Upload']
};


export type MutationUploadImageArgs = {
  site: Scalars['ID'],
  image: Scalars['Upload']
};


export type MutationCreateBlogAuthorArgs = {
  site: Scalars['ID'],
  input: CreateBlogAuthorInput
};


export type MutationUpdateBlogAuthorArgs = {
  site: Scalars['ID'],
  id: Scalars['ID'],
  input: UpdateBlogAuthorInput
};


export type MutationCreateBlogPostArgs = {
  site: Scalars['ID'],
  input: CreateBlogPostInput
};


export type MutationUpdateBlogPostArgs = {
  site: Scalars['ID'],
  id: Scalars['ID'],
  input: UpdateBlogPostInput
};


export type MutationDeleteBlogPostArgs = {
  site: Scalars['ID'],
  id: Scalars['ID']
};


export type MutationCreateProductArgs = {
  site: Scalars['ID'],
  product: CreateProductInput
};


export type MutationUpdateProductArgs = {
  site: Scalars['ID'],
  product: UpdateProductInput
};


export type MutationDeleteProductArgs = {
  site: Scalars['ID'],
  product: Scalars['ID']
};


export type MutationEnableProductArgs = {
  site: Scalars['ID'],
  product: Scalars['ID']
};


export type MutationDisableProductArgs = {
  site: Scalars['ID'],
  product: Scalars['ID']
};


export type MutationUploadProductImageArgs = {
  site: Scalars['ID'],
  image: Scalars['Upload']
};


export type MutationSaveCommerceSettingsArgs = {
  site: Scalars['ID'],
  input: CommerceSettingsInput
};


export type MutationUpdateFoodItemArgs = {
  menu: Scalars['ID'],
  food: Scalars['ID'],
  input: UpdateFoodItemInput
};


export type MutationCreateFoodMenuArgs = {
  site: Scalars['ID'],
  input: CreateFoodMenuInput
};


export type MutationUpdateFoodMenuArgs = {
  site: Scalars['ID'],
  id: Scalars['ID'],
  input: UpdateFoodMenuInput
};


export type MutationUploadFoodItemThumbnailArgs = {
  site: Scalars['ID'],
  image: Scalars['Upload']
};


export type MutationUploadFoodItemPhotoArgs = {
  site: Scalars['ID'],
  image: Scalars['Upload']
};


export type MutationDeleteFoodMenuArgs = {
  site: Scalars['ID'],
  menu: Scalars['ID']
};


export type MutationSaveThemeFileArgs = {
  site: Scalars['ID'],
  path: Scalars['String'],
  content: Scalars['String']
};


export type MutationCreateUserArgs = {
  user: CreateUserInput
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'],
  user: UpdateUserInput
};


export type MutationCreateRedirectArgs = {
  site: Scalars['ID'],
  source: Scalars['String'],
  dest: Scalars['String'],
  type: RedirectType
};


export type MutationSaveRedirectArgs = {
  site: Scalars['ID'],
  redirect: Scalars['ID'],
  source: Scalars['String'],
  dest: Scalars['String'],
  type: RedirectType
};


export type MutationDeleteRedirectArgs = {
  site: Scalars['ID'],
  redirect: Scalars['ID']
};


export type MutationCreateMenuArgs = {
  site: Scalars['ID'],
  menu: CreateMenuInput
};


export type MutationSaveMenuArgs = {
  site: Scalars['ID'],
  id: Scalars['ID'],
  menu: SaveMenuInput
};


export type MutationDeleteMenuArgs = {
  site: Scalars['ID'],
  menu: Scalars['ID']
};


export type MutationCreatePageArgs = {
  site: Scalars['ID'],
  input: CreatePageInput
};


export type MutationUpdatePageArgs = {
  site: Scalars['ID'],
  page: Scalars['ID'],
  input: UpdatePageInput
};


export type MutationRemovePageArgs = {
  site: Scalars['ID'],
  page: Scalars['ID']
};


export type MutationEnablePageArgs = {
  site: Scalars['ID'],
  page: Scalars['ID']
};


export type MutationDisablePageArgs = {
  site: Scalars['ID'],
  page: Scalars['ID']
};


export type MutationCreateFormArgs = {
  site: Scalars['ID'],
  input: CreateFormInput
};


export type MutationSaveFormArgs = {
  site: Scalars['ID'],
  form: Scalars['ID'],
  input: SaveFormInput
};


export type MutationDeleteFormArgs = {
  site: Scalars['ID'],
  form: Scalars['ID']
};


export type MutationMarkFormsAsSpamArgs = {
  site: Scalars['ID'],
  forms: Array<Scalars['ID']>
};


export type MutationMarkFormsAsNotSpamArgs = {
  site: Scalars['ID'],
  forms: Array<Scalars['ID']>
};

export type Order = {
   __typename?: 'Order',
  id: Scalars['ID'],
  siteId: Scalars['ID'],
  customer: Customer,
  billingAddress: Scalars['JSON'],
  shippingAddress: Scalars['JSON'],
  status: Scalars['String'],
  total: Scalars['Int'],
  shipping: Scalars['Int'],
  taxes: Scalars['Int'],
  items: Array<OrderItem>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type OrderItem = {
   __typename?: 'OrderItem',
  id: Scalars['ID'],
  product: Product,
  productSnapshot: Scalars['JSON'],
  attributes: Array<OrderItemAttributeSelection>,
  attributeSelections: Scalars['JSON'],
  deliverables: Array<Scalars['JSONObject']>,
  total: Scalars['Int'],
};

export type OrderItemAttributeSelection = {
   __typename?: 'OrderItemAttributeSelection',
  name: Scalars['String'],
  selection: Scalars['String'],
};

export type Page = {
   __typename?: 'Page',
  id: Scalars['ID'],
  siteId: Scalars['ID'],
  url: Scalars['String'],
  name: Scalars['String'],
  content: Array<Scalars['JSONObject']>,
  seo: PageSeo,
  enabled: Scalars['Boolean'],
  isTemplate: Scalars['Boolean'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type PageInfo = {
   __typename?: 'PageInfo',
  hasNextPage: Scalars['Boolean'],
  hasPreviousPage: Scalars['Boolean'],
};

export type PageSeo = {
   __typename?: 'PageSeo',
  title?: Maybe<Scalars['String']>,
  keywords?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  isIndexable: Scalars['Boolean'],
};

export type PageSeoInput = {
  title?: Maybe<Scalars['String']>,
  keywords?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  isIndexable?: Maybe<Scalars['Boolean']>,
};

export type Product = {
   __typename?: 'Product',
  id: Scalars['ID'],
  siteId: Scalars['ID'],
  name: Scalars['String'],
  shippingType: ShippingType,
  taxable: Scalars['Boolean'],
  enabled: Scalars['Boolean'],
  description: Scalars['String'],
  seo: SeoMetadata,
  slug: Scalars['String'],
  image?: Maybe<Scalars['String']>,
  images: Array<ProductImage>,
  price?: Maybe<Scalars['Int']>,
  attributes: Array<ProductAttribute>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type ProductAttribute = {
   __typename?: 'ProductAttribute',
  id: Scalars['ID'],
  name: Scalars['String'],
  choices: Array<ProductAttributeChoice>,
  required: Scalars['Boolean'],
};

export type ProductAttributeChoice = {
   __typename?: 'ProductAttributeChoice',
  id: Scalars['ID'],
  label: Scalars['String'],
  price?: Maybe<Scalars['Int']>,
};

export type ProductAttributeChoiceInput = {
  id: Scalars['ID'],
  label: Scalars['String'],
  price?: Maybe<Scalars['Int']>,
};

export type ProductAttributeInput = {
  id: Scalars['ID'],
  name: Scalars['String'],
  required: Scalars['Boolean'],
  choices: Array<ProductAttributeChoiceInput>,
};

export type ProductImage = {
   __typename?: 'ProductImage',
  id: Scalars['ID'],
  url: Scalars['String'],
};

export type Query = {
   __typename?: 'Query',
  sites: Array<Site>,
  site: Site,
  routes: Array<SiteRoute>,
  blogPosts: BlogPostsConnection,
  blogPost: BlogPost,
  blogPostAuthors: Array<BlogPostAuthor>,
  blogPostAuthor: BlogPostAuthor,
  customers: Array<Customer>,
  customer: Customer,
  products: Array<Product>,
  product: Product,
  orders: Array<Order>,
  order: Order,
  commerceSettings: CommerceSettings,
  salesReport: Array<SalesBreakdown>,
  taxReport: Array<TaxBreakdown>,
  foodMenus: Array<FoodMenu>,
  foodMenu: FoodMenu,
  themeFileTree: ThemeFileTree,
  themeFile: ThemeFile,
  users: Array<User>,
  user: User,
  events: EventConnection,
  event: Event,
  redirects: Array<Redirect>,
  redirect: Redirect,
  menus: Array<Menu>,
  menu: Menu,
  pages: Array<Page>,
  page: Page,
  forms: Array<Form>,
  form: Form,
  formSubmissions: Array<FormSubmission>,
  formSubmission: FormSubmission,
  latestFormSubmissions: Array<FormSubmission>,
  submissionsPerDay: Array<DailySubmissionStat>,
};


export type QuerySiteArgs = {
  id: Scalars['ID']
};


export type QueryRoutesArgs = {
  site: Scalars['ID']
};


export type QueryBlogPostsArgs = {
  site: Scalars['ID'],
  first?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>
};


export type QueryBlogPostArgs = {
  site: Scalars['ID'],
  post: Scalars['ID']
};


export type QueryBlogPostAuthorsArgs = {
  site: Scalars['ID']
};


export type QueryBlogPostAuthorArgs = {
  site: Scalars['ID'],
  author: Scalars['ID']
};


export type QueryCustomersArgs = {
  site: Scalars['ID']
};


export type QueryCustomerArgs = {
  site: Scalars['ID'],
  id: Scalars['ID']
};


export type QueryProductsArgs = {
  site: Scalars['ID']
};


export type QueryProductArgs = {
  site: Scalars['ID'],
  id: Scalars['ID']
};


export type QueryOrdersArgs = {
  site: Scalars['ID']
};


export type QueryOrderArgs = {
  site: Scalars['ID'],
  id: Scalars['ID']
};


export type QueryCommerceSettingsArgs = {
  site: Scalars['ID']
};


export type QuerySalesReportArgs = {
  site: Scalars['ID'],
  range?: Maybe<Scalars['String']>,
  dates?: Maybe<Scalars['String']>,
  products?: Maybe<Array<Scalars['ID']>>
};


export type QueryTaxReportArgs = {
  site: Scalars['ID'],
  range?: Maybe<Scalars['String']>,
  dates?: Maybe<Scalars['String']>
};


export type QueryFoodMenusArgs = {
  site: Scalars['ID']
};


export type QueryFoodMenuArgs = {
  site: Scalars['ID'],
  id: Scalars['ID']
};


export type QueryThemeFileTreeArgs = {
  site: Scalars['ID']
};


export type QueryThemeFileArgs = {
  site: Scalars['ID'],
  path: Scalars['String']
};


export type QueryUserArgs = {
  id: Scalars['ID']
};


export type QueryEventsArgs = {
  site: Scalars['ID'],
  first?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>
};


export type QueryEventArgs = {
  site: Scalars['ID'],
  id: Scalars['ID']
};


export type QueryRedirectsArgs = {
  site: Scalars['ID']
};


export type QueryRedirectArgs = {
  site: Scalars['ID'],
  redirect: Scalars['ID']
};


export type QueryMenusArgs = {
  site: Scalars['ID']
};


export type QueryMenuArgs = {
  site: Scalars['ID'],
  id: Scalars['ID']
};


export type QueryPagesArgs = {
  site: Scalars['ID']
};


export type QueryPageArgs = {
  site: Scalars['ID'],
  page: Scalars['ID']
};


export type QueryFormsArgs = {
  site: Scalars['ID']
};


export type QueryFormArgs = {
  site: Scalars['ID'],
  form: Scalars['ID']
};


export type QueryFormSubmissionsArgs = {
  site: Scalars['ID'],
  form: Scalars['ID'],
  includeSpam?: Maybe<Scalars['Boolean']>
};


export type QueryFormSubmissionArgs = {
  site: Scalars['ID'],
  submission: Scalars['ID']
};


export type QueryLatestFormSubmissionsArgs = {
  site: Scalars['ID'],
  first: Scalars['Int']
};


export type QuerySubmissionsPerDayArgs = {
  site: Scalars['ID']
};

export type Redirect = {
   __typename?: 'Redirect',
  id: Scalars['ID'],
  siteId: Scalars['ID'],
  source: Scalars['String'],
  dest: Scalars['String'],
  redirectType: RedirectType,
};

export enum RedirectType {
  Permanent = 'permanent',
  Temporary = 'temporary'
}

export type RemovePagePayload = {
   __typename?: 'RemovePagePayload',
  site: Scalars['ID'],
  page: Scalars['ID'],
};

export type SalesBreakdown = {
   __typename?: 'SalesBreakdown',
  year: Scalars['Int'],
  month: Scalars['Int'],
  day: Scalars['Int'],
  sales: Scalars['Int'],
  total: Scalars['Int'],
};

export type SaveFormInput = {
  name: Scalars['String'],
  fields: Scalars['JSON'],
  notify: Array<Scalars['String']>,
  followUp?: Maybe<Scalars['String']>,
  buttonText?: Maybe<Scalars['String']>,
};

export type SaveFormPayload = {
   __typename?: 'SaveFormPayload',
  form: Form,
};

export type SaveMenuInput = {
  name: Scalars['String'],
  items: Array<MenuItemInput>,
};

export type SaveMenuPayload = {
   __typename?: 'SaveMenuPayload',
  errors: Array<Error>,
  menu: Menu,
};

export type SaveRedirectPayload = {
   __typename?: 'SaveRedirectPayload',
  redirect: Redirect,
};

export type SaveThemeFilePayload = {
   __typename?: 'SaveThemeFilePayload',
  path: Scalars['String'],
};

export type SeoMetadata = {
   __typename?: 'SeoMetadata',
  title: Scalars['String'],
  keywords: Scalars['String'],
  description: Scalars['String'],
};

export type SeoMetadataInput = {
  title?: Maybe<Scalars['String']>,
  keywords?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
};

export enum ShippingType {
  None = 'none',
  Flat = 'flat',
  Table = 'table'
}

export type Site = {
   __typename?: 'Site',
  id: Scalars['ID'],
  code: Scalars['String'],
  domain: Scalars['String'],
  name: Scalars['String'],
  settings: Scalars['JSONObject'],
  assets: Array<ImageAsset>,
  otherDomains: Array<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type SiteRoute = {
   __typename?: 'SiteRoute',
  id: Scalars['ID'],
  type: Scalars['String'],
  name: Scalars['String'],
  url: Scalars['String'],
};

export type SiteSettingsInput = {
  name: Scalars['String'],
  gtmCode?: Maybe<Scalars['String']>,
  stripeSecretKey?: Maybe<Scalars['String']>,
  stripePublicKey?: Maybe<Scalars['String']>,
  receiptThankYouMessage?: Maybe<Scalars['String']>,
  orderNotificationEmail?: Maybe<Scalars['String']>,
  sellerName?: Maybe<Scalars['String']>,
  sellerAddress?: Maybe<Scalars['String']>,
  sellerPhone?: Maybe<Scalars['String']>,
  sellerSupportEmail?: Maybe<Scalars['String']>,
};

export type TableRateSettings = {
   __typename?: 'TableRateSettings',
  min: Scalars['Int'],
  max: Scalars['Int'],
  rate: Scalars['Int'],
};

export type TableRateSettingsInput = {
  min: Scalars['Int'],
  max: Scalars['Int'],
  rate: Scalars['Int'],
};

export type TaxBreakdown = {
   __typename?: 'TaxBreakdown',
  year: Scalars['Int'],
  month: Scalars['Int'],
  day: Scalars['Int'],
  sales: Scalars['Int'],
  total: Scalars['Int'],
  shipping: Scalars['Int'],
  taxes: Scalars['Int'],
};

export type ThemeFile = {
   __typename?: 'ThemeFile',
  id: Scalars['ID'],
  path: Scalars['String'],
  content: Scalars['String'],
};

export type ThemeFileTree = {
   __typename?: 'ThemeFileTree',
  id: Scalars['ID'],
  siteId: Scalars['ID'],
  files: Scalars['JSON'],
};


export type UpdateBlogAuthorInput = {
  name: Scalars['String'],
  email: Scalars['String'],
  photo: Scalars['String'],
  title: Scalars['String'],
  url?: Maybe<Scalars['String']>,
};

export type UpdateBlogAuthorPayload = {
   __typename?: 'UpdateBlogAuthorPayload',
  blogAuthor: BlogPostAuthor,
};

export type UpdateBlogPostInput = {
  authorId?: Maybe<Scalars['ID']>,
  title: Scalars['String'],
  slug: Scalars['String'],
  content: Scalars['String'],
  tags: Array<Scalars['String']>,
  heroImage?: Maybe<Scalars['String']>,
  isPublished?: Maybe<Scalars['Boolean']>,
  seo?: Maybe<SeoMetadataInput>,
};

export type UpdateBlogPostPayload = {
   __typename?: 'UpdateBlogPostPayload',
  blogPost: BlogPost,
};

export type UpdateFoodItemInput = {
  title?: Maybe<Scalars['String']>,
};

export type UpdateFoodItemPayload = {
   __typename?: 'UpdateFoodItemPayload',
  item?: Maybe<FoodMenuItem>,
};

export type UpdateFoodMenuInput = {
  name: Scalars['String'],
  sections: Array<Maybe<FoodMenuSectionInput>>,
};

export type UpdateFoodMenuPayload = {
   __typename?: 'UpdateFoodMenuPayload',
  foodMenu: FoodMenu,
};

export type UpdatePageInput = {
  name: Scalars['String'],
  url: Scalars['String'],
  content: Array<Scalars['JSONObject']>,
  seo: PageSeoInput,
  enabled: Scalars['Boolean'],
};

export type UpdatePagePayload = {
   __typename?: 'UpdatePagePayload',
  page: Page,
};

export type UpdateProductInput = {
  id: Scalars['ID'],
  name: Scalars['String'],
  slug: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  shippingType: ShippingType,
  taxable: Scalars['Boolean'],
  enabled: Scalars['Boolean'],
  seo?: Maybe<SeoMetadataInput>,
  image?: Maybe<Scalars['String']>,
  images: Array<Scalars['String']>,
  price?: Maybe<Scalars['Int']>,
  attributes: Array<ProductAttributeInput>,
};

export type UpdateProductPayload = {
   __typename?: 'UpdateProductPayload',
  product: Product,
};

export type UpdateSiteInput = {
  name: Scalars['String'],
  otherDomains?: Maybe<Array<Scalars['String']>>,
};

export type UpdateUserInput = {
  username: Scalars['String'],
  password?: Maybe<Scalars['String']>,
  sites: Array<Scalars['ID']>,
};

export type UpdateUserPayload = {
   __typename?: 'UpdateUserPayload',
  user?: Maybe<User>,
};


export type User = {
   __typename?: 'User',
  id: Scalars['ID'],
  username: Scalars['String'],
  sites: Array<Site>,
  isSuperUser: Scalars['Boolean'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type BlogPostFieldsFragment = (
  { __typename?: 'BlogPost' }
  & Pick<BlogPost, 'id' | 'slug' | 'title' | 'content' | 'tags' | 'isPublished' | 'heroImage'>
  & { author: Maybe<(
    { __typename?: 'BlogPostAuthor' }
    & Pick<BlogPostAuthor, 'id' | 'name' | 'email' | 'photo' | 'url'>
  )>, seo: Maybe<(
    { __typename?: 'SeoMetadata' }
    & Pick<SeoMetadata, 'title' | 'keywords' | 'description'>
  )> }
);

export type BlogAuthorFieldsFragment = (
  { __typename?: 'BlogPostAuthor' }
  & Pick<BlogPostAuthor, 'id' | 'name' | 'email' | 'title' | 'photo' | 'url'>
);

export type BlogPostQueryVariables = {
  site: Scalars['ID'],
  post: Scalars['ID']
};


export type BlogPostQuery = (
  { __typename?: 'Query' }
  & { blogPost: (
    { __typename?: 'BlogPost' }
    & BlogPostFieldsFragment
  ) }
);

export type GetBlogPostsQueryVariables = {
  site: Scalars['ID']
};


export type GetBlogPostsQuery = (
  { __typename?: 'Query' }
  & { blogPosts: (
    { __typename?: 'BlogPostsConnection' }
    & Pick<BlogPostsConnection, 'totalCount'>
    & { edges: Array<(
      { __typename?: 'BlogPostsEdge' }
      & { node: (
        { __typename?: 'BlogPost' }
        & Pick<BlogPost, 'id' | 'slug' | 'url' | 'title' | 'tags' | 'isPublished'>
        & { author: Maybe<(
          { __typename?: 'BlogPostAuthor' }
          & Pick<BlogPostAuthor, 'name'>
        )> }
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage'>
    ) }
  ) }
);

export type GetBlogPostAuthorsQueryVariables = {
  site: Scalars['ID']
};


export type GetBlogPostAuthorsQuery = (
  { __typename?: 'Query' }
  & { blogPostAuthors: Array<(
    { __typename?: 'BlogPostAuthor' }
    & BlogAuthorFieldsFragment
  )> }
);

export type BlogPostAuthorQueryVariables = {
  site: Scalars['ID'],
  author: Scalars['ID']
};


export type BlogPostAuthorQuery = (
  { __typename?: 'Query' }
  & { blogPostAuthor: (
    { __typename?: 'BlogPostAuthor' }
    & BlogAuthorFieldsFragment
  ) }
);

export type CreateBlogPostMutationVariables = {
  site: Scalars['ID'],
  input: CreateBlogPostInput
};


export type CreateBlogPostMutation = (
  { __typename?: 'Mutation' }
  & { createBlogPost: (
    { __typename?: 'CreateBlogPostPayload' }
    & { blogPost: (
      { __typename?: 'BlogPost' }
      & BlogPostFieldsFragment
    ) }
  ) }
);

export type UpdateBlogPostMutationVariables = {
  site: Scalars['ID'],
  id: Scalars['ID'],
  input: UpdateBlogPostInput
};


export type UpdateBlogPostMutation = (
  { __typename?: 'Mutation' }
  & { updateBlogPost: (
    { __typename?: 'UpdateBlogPostPayload' }
    & { blogPost: (
      { __typename?: 'BlogPost' }
      & BlogPostFieldsFragment
    ) }
  ) }
);

export type UploadBlogImageMutationVariables = {
  site: Scalars['ID'],
  image: Scalars['Upload']
};


export type UploadBlogImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadImage: (
    { __typename?: 'ImageUpload' }
    & Pick<ImageUpload, 'url'>
  ) }
);

export type DeleteBlogPostMutationVariables = {
  site: Scalars['ID'],
  id: Scalars['ID']
};


export type DeleteBlogPostMutation = (
  { __typename?: 'Mutation' }
  & { deleteBlogPost: (
    { __typename?: 'DeleteBlogPostPayload' }
    & Pick<DeleteBlogPostPayload, 'site' | 'post'>
  ) }
);

export type CreateBlogAuthorMutationVariables = {
  site: Scalars['ID'],
  input: CreateBlogAuthorInput
};


export type CreateBlogAuthorMutation = (
  { __typename?: 'Mutation' }
  & { createBlogAuthor: (
    { __typename?: 'CreateBlogAuthorPayload' }
    & { blogAuthor: (
      { __typename?: 'BlogPostAuthor' }
      & BlogAuthorFieldsFragment
    ) }
  ) }
);

export type UpdateBlogAuthorMutationVariables = {
  site: Scalars['ID'],
  id: Scalars['ID'],
  input: UpdateBlogAuthorInput
};


export type UpdateBlogAuthorMutation = (
  { __typename?: 'Mutation' }
  & { updateBlogAuthor: (
    { __typename?: 'UpdateBlogAuthorPayload' }
    & { blogAuthor: (
      { __typename?: 'BlogPostAuthor' }
      & BlogAuthorFieldsFragment
    ) }
  ) }
);

export type CommerceSettingsQueryVariables = {
  site: Scalars['ID']
};


export type CommerceSettingsQuery = (
  { __typename?: 'Query' }
  & { commerceSettings: (
    { __typename?: 'CommerceSettings' }
    & { tableRate: Array<(
      { __typename?: 'TableRateSettings' }
      & Pick<TableRateSettings, 'min' | 'max' | 'rate'>
    )> }
  ) }
);

export type SaveCommerceSettingsMutationVariables = {
  site: Scalars['ID'],
  settings: CommerceSettingsInput
};


export type SaveCommerceSettingsMutation = (
  { __typename?: 'Mutation' }
  & { saveCommerceSettings: (
    { __typename?: 'CommerceSettings' }
    & { tableRate: Array<(
      { __typename?: 'TableRateSettings' }
      & Pick<TableRateSettings, 'min' | 'max' | 'rate'>
    )> }
  ) }
);

export type GetOrderDetailsQueryVariables = {
  site: Scalars['ID'],
  id: Scalars['ID']
};


export type GetOrderDetailsQuery = (
  { __typename?: 'Query' }
  & { order: (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'status' | 'total' | 'shipping' | 'taxes' | 'billingAddress' | 'shippingAddress' | 'createdAt' | 'updatedAt'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'name' | 'email'>
    ), items: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'total' | 'deliverables'>
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name'>
      ), attributes: Array<(
        { __typename?: 'OrderItemAttributeSelection' }
        & Pick<OrderItemAttributeSelection, 'name' | 'selection'>
      )> }
    )> }
  ) }
);

export type GetOrdersQueryVariables = {
  site: Scalars['ID']
};


export type GetOrdersQuery = (
  { __typename?: 'Query' }
  & { orders: Array<(
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'total' | 'status' | 'createdAt'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'name' | 'email'>
    ) }
  )> }
);

export type UploadProductImageMutationVariables = {
  site: Scalars['ID'],
  image: Scalars['Upload']
};


export type UploadProductImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadProductImage: (
    { __typename?: 'BasicFile' }
    & Pick<BasicFile, 'url'>
  ) }
);

export type ProductFieldsFragment = (
  { __typename?: 'Product' }
  & Pick<Product, 'name' | 'description' | 'shippingType' | 'taxable' | 'slug' | 'image' | 'price' | 'enabled'>
  & { images: Array<(
    { __typename?: 'ProductImage' }
    & Pick<ProductImage, 'url'>
  )>, seo: (
    { __typename?: 'SeoMetadata' }
    & Pick<SeoMetadata, 'title' | 'keywords' | 'description'>
  ), attributes: Array<(
    { __typename?: 'ProductAttribute' }
    & Pick<ProductAttribute, 'id' | 'name' | 'required'>
    & { choices: Array<(
      { __typename?: 'ProductAttributeChoice' }
      & Pick<ProductAttributeChoice, 'id' | 'label' | 'price'>
    )> }
  )> }
);

export type GetProductQueryVariables = {
  site: Scalars['ID'],
  id: Scalars['ID']
};


export type GetProductQuery = (
  { __typename?: 'Query' }
  & { product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id'>
    & ProductFieldsFragment
  ) }
);

export type CreateProductMutationVariables = {
  site: Scalars['ID'],
  product: CreateProductInput
};


export type CreateProductMutation = (
  { __typename?: 'Mutation' }
  & { createProduct: (
    { __typename?: 'CreateProductPayload' }
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id'>
      & ProductFieldsFragment
    ) }
  ) }
);

export type UpdateProductMutationVariables = {
  site: Scalars['ID'],
  product: UpdateProductInput
};


export type UpdateProductMutation = (
  { __typename?: 'Mutation' }
  & { updateProduct: (
    { __typename?: 'UpdateProductPayload' }
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id'>
      & ProductFieldsFragment
    ) }
  ) }
);

export type GetProductsQueryVariables = {
  site: Scalars['ID']
};


export type GetProductsQuery = (
  { __typename?: 'Query' }
  & { products: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'taxable' | 'shippingType' | 'enabled'>
  )> }
);

export type DeleteProductMutationVariables = {
  site: Scalars['ID'],
  product: Scalars['ID']
};


export type DeleteProductMutation = (
  { __typename?: 'Mutation' }
  & { deleteProduct: (
    { __typename?: 'DeleteProductPayload' }
    & Pick<DeleteProductPayload, 'site' | 'product'>
  ) }
);

export type EnableProductMutationVariables = {
  site: Scalars['ID'],
  product: Scalars['ID']
};


export type EnableProductMutation = (
  { __typename?: 'Mutation' }
  & { enableProduct: (
    { __typename?: 'EnableProductPayload' }
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'enabled'>
    ) }
  ) }
);

export type DisableProductMutationVariables = {
  site: Scalars['ID'],
  product: Scalars['ID']
};


export type DisableProductMutation = (
  { __typename?: 'Mutation' }
  & { disableProduct: (
    { __typename?: 'DisableProductPayload' }
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'enabled'>
    ) }
  ) }
);

export type GetProductIdsAndNamesQueryVariables = {
  site: Scalars['ID']
};


export type GetProductIdsAndNamesQuery = (
  { __typename?: 'Query' }
  & { products: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name'>
  )> }
);

export type SalesReportQueryVariables = {
  site: Scalars['ID'],
  range?: Maybe<Scalars['String']>,
  dates?: Maybe<Scalars['String']>,
  products?: Maybe<Array<Scalars['ID']>>
};


export type SalesReportQuery = (
  { __typename?: 'Query' }
  & { salesReport: Array<(
    { __typename?: 'SalesBreakdown' }
    & Pick<SalesBreakdown, 'year' | 'month' | 'day' | 'sales' | 'total'>
  )> }
);

export type TaxReportQueryVariables = {
  site: Scalars['ID'],
  range?: Maybe<Scalars['String']>,
  dates?: Maybe<Scalars['String']>
};


export type TaxReportQuery = (
  { __typename?: 'Query' }
  & { taxReport: Array<(
    { __typename?: 'TaxBreakdown' }
    & Pick<TaxBreakdown, 'year' | 'month' | 'day' | 'sales' | 'total' | 'taxes' | 'shipping'>
  )> }
);

export type EventFieldsFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'title' | 'description'>
);

export type GetEventQueryVariables = {
  site: Scalars['ID'],
  id: Scalars['ID']
};


export type GetEventQuery = (
  { __typename?: 'Query' }
  & { event: (
    { __typename?: 'Event' }
    & EventFieldsFragment
  ) }
);

export type GetEventListQueryVariables = {
  site: Scalars['ID'],
  first?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>
};


export type GetEventListQuery = (
  { __typename?: 'Query' }
  & { events: (
    { __typename?: 'EventConnection' }
    & { edges: Array<(
      { __typename?: 'EventEdge' }
      & { node: (
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'title'>
      ) }
    )> }
  ) }
);

export type UploadThumbnailMutationVariables = {
  site: Scalars['ID'],
  image: Scalars['Upload']
};


export type UploadThumbnailMutation = (
  { __typename?: 'Mutation' }
  & { uploadFoodItemThumbnail: (
    { __typename?: 'BasicFile' }
    & Pick<BasicFile, 'url'>
  ) }
);

export type ItemStuffFragment = (
  { __typename?: 'FoodMenuItem' }
  & Pick<FoodMenuItem, 'id' | 'title' | 'description' | 'price' | 'photo'>
  & { additional: Array<(
    { __typename?: 'FoodMenuItemMeta' }
    & Pick<FoodMenuItemMeta, 'label' | 'value'>
  )>, images: (
    { __typename?: 'FoodMenuItemImages' }
    & Pick<FoodMenuItemImages, 'thumbnail'>
  ) }
);

export type GetFoodMenuQueryVariables = {
  site: Scalars['ID'],
  id: Scalars['ID']
};


export type GetFoodMenuQuery = (
  { __typename?: 'Query' }
  & { foodMenu: (
    { __typename?: 'FoodMenu' }
    & Pick<FoodMenu, 'name'>
    & { sections: Array<(
      { __typename?: 'FoodMenuSection' }
      & Pick<FoodMenuSection, 'id' | 'title' | 'description'>
      & { items: Array<(
        { __typename?: 'FoodMenuItem' }
        & ItemStuffFragment
      )> }
    )> }
  ) }
);

export type CreateFoodMenuMutationVariables = {
  site: Scalars['ID'],
  input: CreateFoodMenuInput
};


export type CreateFoodMenuMutation = (
  { __typename?: 'Mutation' }
  & { createFoodMenu: (
    { __typename?: 'CreateFoodMenuPayload' }
    & { foodMenu: (
      { __typename?: 'FoodMenu' }
      & Pick<FoodMenu, 'id' | 'name'>
      & { sections: Array<(
        { __typename?: 'FoodMenuSection' }
        & Pick<FoodMenuSection, 'id' | 'title' | 'description'>
        & { items: Array<(
          { __typename?: 'FoodMenuItem' }
          & ItemStuffFragment
        )> }
      )> }
    ) }
  ) }
);

export type UpdateFoodMenuMutationVariables = {
  site: Scalars['ID'],
  id: Scalars['ID'],
  input: UpdateFoodMenuInput
};


export type UpdateFoodMenuMutation = (
  { __typename?: 'Mutation' }
  & { updateFoodMenu: (
    { __typename?: 'UpdateFoodMenuPayload' }
    & { foodMenu: (
      { __typename?: 'FoodMenu' }
      & Pick<FoodMenu, 'name'>
      & { sections: Array<(
        { __typename?: 'FoodMenuSection' }
        & Pick<FoodMenuSection, 'id' | 'title' | 'description'>
        & { items: Array<(
          { __typename?: 'FoodMenuItem' }
          & ItemStuffFragment
        )> }
      )> }
    ) }
  ) }
);

export type GetFoodMenuListQueryVariables = {
  site: Scalars['ID']
};


export type GetFoodMenuListQuery = (
  { __typename?: 'Query' }
  & { foodMenus: Array<(
    { __typename?: 'FoodMenu' }
    & Pick<FoodMenu, 'id' | 'name'>
  )> }
);

export type DeleteFoodMenuMutationVariables = {
  site: Scalars['ID'],
  menu: Scalars['ID']
};


export type DeleteFoodMenuMutation = (
  { __typename?: 'Mutation' }
  & { deleteFoodMenu: (
    { __typename?: 'DeleteFoodMenuPayload' }
    & Pick<DeleteFoodMenuPayload, 'site' | 'menu'>
  ) }
);

export type FormFieldsFragment = (
  { __typename?: 'Form' }
  & Pick<Form, 'name' | 'fields' | 'notify' | 'followUp' | 'buttonText' | 'createdAt' | 'updatedAt'>
);

export type GetFormDetailsQueryVariables = {
  site: Scalars['ID'],
  form: Scalars['ID']
};


export type GetFormDetailsQuery = (
  { __typename?: 'Query' }
  & { form: (
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'siteId'>
    & FormFieldsFragment
  ) }
);

export type CreateFormMutationVariables = {
  site: Scalars['ID'],
  input: CreateFormInput
};


export type CreateFormMutation = (
  { __typename?: 'Mutation' }
  & { createForm: (
    { __typename?: 'CreateFormPayload' }
    & { form: (
      { __typename?: 'Form' }
      & Pick<Form, 'id' | 'siteId'>
      & FormFieldsFragment
    ) }
  ) }
);

export type SaveFormMutationVariables = {
  site: Scalars['ID'],
  form: Scalars['ID'],
  input: SaveFormInput
};


export type SaveFormMutation = (
  { __typename?: 'Mutation' }
  & { saveForm: (
    { __typename?: 'SaveFormPayload' }
    & { form: (
      { __typename?: 'Form' }
      & Pick<Form, 'id' | 'siteId'>
      & FormFieldsFragment
    ) }
  ) }
);

export type LatestFormSubmissionsQueryVariables = {
  site: Scalars['ID'],
  first: Scalars['Int']
};


export type LatestFormSubmissionsQuery = (
  { __typename?: 'Query' }
  & { submissionsPerDay: Array<(
    { __typename?: 'DailySubmissionStat' }
    & Pick<DailySubmissionStat, 'total'>
  )>, latestFormSubmissions: Array<(
    { __typename?: 'FormSubmission' }
    & Pick<FormSubmission, 'id' | 'teaser' | 'createdAt'>
    & { form: (
      { __typename?: 'Form' }
      & Pick<Form, 'id' | 'name'>
    ) }
  )> }
);

export type GetFormsQueryVariables = {
  site: Scalars['ID']
};


export type GetFormsQuery = (
  { __typename?: 'Query' }
  & { forms: Array<(
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'name' | 'submissionCount'>
    & { submissionsPerDay: Array<(
      { __typename?: 'DailySubmissionStat' }
      & Pick<DailySubmissionStat, 'total'>
    )> }
  )> }
);

export type DeleteFormMutationVariables = {
  site: Scalars['ID'],
  form: Scalars['ID']
};


export type DeleteFormMutation = (
  { __typename?: 'Mutation' }
  & { deleteForm: (
    { __typename?: 'DeleteFormPayload' }
    & Pick<DeleteFormPayload, 'id'>
  ) }
);

export type GetFormSubmissionQueryVariables = {
  site: Scalars['ID'],
  submission: Scalars['ID']
};


export type GetFormSubmissionQuery = (
  { __typename?: 'Query' }
  & { formSubmission: (
    { __typename?: 'FormSubmission' }
    & Pick<FormSubmission, 'id' | 'teaser' | 'labels' | 'values' | 'isSpam' | 'createdAt'>
    & { form: (
      { __typename?: 'Form' }
      & Pick<Form, 'id' | 'name'>
    ), submitter: (
      { __typename?: 'FormSubmitterInfo' }
      & Pick<FormSubmitterInfo, 'initials'>
    ) }
  ) }
);

export type MarkFormAsSpamMutationVariables = {
  site: Scalars['ID'],
  forms: Array<Scalars['ID']>
};


export type MarkFormAsSpamMutation = (
  { __typename?: 'Mutation' }
  & { markFormsAsSpam: Array<(
    { __typename?: 'FormSubmission' }
    & Pick<FormSubmission, 'id' | 'isSpam'>
  )> }
);

export type MarkFormAsNotSpamMutationVariables = {
  site: Scalars['ID'],
  forms: Array<Scalars['ID']>
};


export type MarkFormAsNotSpamMutation = (
  { __typename?: 'Mutation' }
  & { markFormsAsNotSpam: Array<(
    { __typename?: 'FormSubmission' }
    & Pick<FormSubmission, 'id' | 'isSpam'>
  )> }
);

export type GetFormSubmissionListsQueryVariables = {
  site: Scalars['ID'],
  form: Scalars['ID'],
  teaserLength?: Maybe<Scalars['Int']>
};


export type GetFormSubmissionListsQuery = (
  { __typename?: 'Query' }
  & { allForms: Array<(
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'name'>
  )>, form: (
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'name'>
  ), formSubmissions: Array<(
    { __typename?: 'FormSubmission' }
    & Pick<FormSubmission, 'id' | 'teaser' | 'labels' | 'values' | 'isSpam' | 'createdAt'>
    & { submitter: (
      { __typename?: 'FormSubmitterInfo' }
      & Pick<FormSubmitterInfo, 'initials'>
    ) }
  )> }
);

export type GetSiteAssetsQueryVariables = {
  site: Scalars['ID']
};


export type GetSiteAssetsQuery = (
  { __typename?: 'Query' }
  & { site: (
    { __typename?: 'Site' }
    & { assets: Array<(
      { __typename?: 'ImageAsset' }
      & Pick<ImageAsset, 'id' | 'name' | 'url' | 'size'>
      & { dimensions: (
        { __typename?: 'ImageDimensions' }
        & Pick<ImageDimensions, 'width' | 'height'>
      ) }
    )> }
  ) }
);

export type GetSiteRoutesQueryVariables = {
  site: Scalars['ID']
};


export type GetSiteRoutesQuery = (
  { __typename?: 'Query' }
  & { routes: Array<(
    { __typename?: 'SiteRoute' }
    & Pick<SiteRoute, 'id' | 'type' | 'name' | 'url'>
  )> }
);

export type MenuFieldsFragment = (
  { __typename?: 'Menu' }
  & Pick<Menu, 'id' | 'name'>
  & { items: Array<(
    { __typename?: 'MenuItem' }
    & { children: Array<(
      { __typename?: 'MenuItem' }
      & { children: Array<(
        { __typename?: 'MenuItem' }
        & ItemFieldsFragment
      )> }
      & ItemFieldsFragment
    )> }
    & ItemFieldsFragment
  )> }
);

export type ItemFieldsFragment = (
  { __typename?: 'MenuItem' }
  & Pick<MenuItem, 'id' | 'label' | 'url'>
);

export type GetMenuQueryVariables = {
  site: Scalars['ID'],
  id: Scalars['ID']
};


export type GetMenuQuery = (
  { __typename?: 'Query' }
  & { menu: (
    { __typename?: 'Menu' }
    & MenuFieldsFragment
  ) }
);

export type CreateMenuMutationVariables = {
  site: Scalars['ID'],
  menu: CreateMenuInput
};


export type CreateMenuMutation = (
  { __typename?: 'Mutation' }
  & { createMenu: (
    { __typename?: 'CreateMenuPayload' }
    & { errors: Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>, menu: (
      { __typename?: 'Menu' }
      & MenuFieldsFragment
    ) }
  ) }
);

export type SaveMenuMutationVariables = {
  site: Scalars['ID'],
  id: Scalars['ID'],
  menu: SaveMenuInput
};


export type SaveMenuMutation = (
  { __typename?: 'Mutation' }
  & { saveMenu: (
    { __typename?: 'SaveMenuPayload' }
    & { errors: Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>, menu: (
      { __typename?: 'Menu' }
      & MenuFieldsFragment
    ) }
  ) }
);

export type GetMenusQueryVariables = {
  site: Scalars['ID']
};


export type GetMenusQuery = (
  { __typename?: 'Query' }
  & { menus: Array<(
    { __typename?: 'Menu' }
    & Pick<Menu, 'id' | 'name'>
  )> }
);

export type DeleteMenuMutationVariables = {
  site: Scalars['ID'],
  menu: Scalars['ID']
};


export type DeleteMenuMutation = (
  { __typename?: 'Mutation' }
  & { deleteMenu: (
    { __typename?: 'DeleteMenuPayload' }
    & Pick<DeleteMenuPayload, 'site' | 'menu'>
  ) }
);

export type FoodMenuPickListQueryVariables = {
  site: Scalars['ID']
};


export type FoodMenuPickListQuery = (
  { __typename?: 'Query' }
  & { foodMenus: Array<(
    { __typename?: 'FoodMenu' }
    & Pick<FoodMenu, 'id' | 'name'>
  )> }
);

export type FormMenuPickListQueryVariables = {
  site: Scalars['ID']
};


export type FormMenuPickListQuery = (
  { __typename?: 'Query' }
  & { forms: Array<(
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'name'>
  )> }
);

export type UploadImageMutationVariables = {
  site: Scalars['ID'],
  image: Scalars['Upload']
};


export type UploadImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadImage: (
    { __typename?: 'ImageUpload' }
    & Pick<ImageUpload, 'url'>
  ) }
);

export type PageFieldsFragment = (
  { __typename?: 'Page' }
  & Pick<Page, 'name' | 'content' | 'url' | 'enabled' | 'isTemplate' | 'createdAt' | 'updatedAt'>
  & { seo: (
    { __typename?: 'PageSeo' }
    & Pick<PageSeo, 'title' | 'keywords' | 'description' | 'isIndexable'>
  ) }
);

export type GetPageQueryVariables = {
  site: Scalars['ID'],
  page: Scalars['ID']
};


export type GetPageQuery = (
  { __typename?: 'Query' }
  & { page: (
    { __typename?: 'Page' }
    & Pick<Page, 'id'>
    & PageFieldsFragment
  ) }
);

export type CreatePageMutationVariables = {
  site: Scalars['ID'],
  input: CreatePageInput
};


export type CreatePageMutation = (
  { __typename?: 'Mutation' }
  & { createPage: (
    { __typename?: 'UpdatePagePayload' }
    & { page: (
      { __typename?: 'Page' }
      & Pick<Page, 'id'>
      & PageFieldsFragment
    ) }
  ) }
);

export type UpdatePageMutationVariables = {
  site: Scalars['ID'],
  page: Scalars['ID'],
  input: UpdatePageInput
};


export type UpdatePageMutation = (
  { __typename?: 'Mutation' }
  & { updatePage: (
    { __typename?: 'UpdatePagePayload' }
    & { page: (
      { __typename?: 'Page' }
      & Pick<Page, 'id'>
      & PageFieldsFragment
    ) }
  ) }
);

export type GetPageListQueryVariables = {
  site: Scalars['ID']
};


export type GetPageListQuery = (
  { __typename?: 'Query' }
  & { pages: Array<(
    { __typename?: 'Page' }
    & Pick<Page, 'id' | 'siteId' | 'name' | 'url' | 'enabled' | 'isTemplate' | 'updatedAt'>
  )> }
);

export type EnablePageMutationVariables = {
  site: Scalars['ID'],
  page: Scalars['ID']
};


export type EnablePageMutation = (
  { __typename?: 'Mutation' }
  & { enablePage: (
    { __typename?: 'Page' }
    & Pick<Page, 'id' | 'enabled'>
  ) }
);

export type DisablePageMutationVariables = {
  site: Scalars['ID'],
  page: Scalars['ID']
};


export type DisablePageMutation = (
  { __typename?: 'Mutation' }
  & { disablePage: (
    { __typename?: 'Page' }
    & Pick<Page, 'id' | 'enabled'>
  ) }
);

export type RemovePageMutationVariables = {
  site: Scalars['ID'],
  page: Scalars['ID']
};


export type RemovePageMutation = (
  { __typename?: 'Mutation' }
  & { removePage: (
    { __typename?: 'RemovePagePayload' }
    & Pick<RemovePagePayload, 'site' | 'page'>
  ) }
);

export type RedirectFragment = (
  { __typename?: 'Redirect' }
  & Pick<Redirect, 'id' | 'siteId' | 'source' | 'dest' | 'redirectType'>
);

export type GetRedirectQueryVariables = {
  site: Scalars['ID'],
  redirect: Scalars['ID']
};


export type GetRedirectQuery = (
  { __typename?: 'Query' }
  & { redirect: (
    { __typename?: 'Redirect' }
    & RedirectFragment
  ) }
);

export type SaveRedirectMutationVariables = {
  site: Scalars['ID'],
  redirect: Scalars['ID'],
  source: Scalars['String'],
  dest: Scalars['String'],
  type: RedirectType
};


export type SaveRedirectMutation = (
  { __typename?: 'Mutation' }
  & { saveRedirect: Maybe<(
    { __typename?: 'SaveRedirectPayload' }
    & { redirect: (
      { __typename?: 'Redirect' }
      & RedirectFragment
    ) }
  )> }
);

export type CreateRedirectMutationVariables = {
  site: Scalars['ID'],
  source: Scalars['String'],
  dest: Scalars['String'],
  type: RedirectType
};


export type CreateRedirectMutation = (
  { __typename?: 'Mutation' }
  & { createRedirect: Maybe<(
    { __typename?: 'SaveRedirectPayload' }
    & { redirect: (
      { __typename?: 'Redirect' }
      & RedirectFragment
    ) }
  )> }
);

export type GetRedirectsQueryVariables = {
  site: Scalars['ID']
};


export type GetRedirectsQuery = (
  { __typename?: 'Query' }
  & { redirects: Array<(
    { __typename?: 'Redirect' }
    & Pick<Redirect, 'id' | 'source' | 'dest' | 'redirectType'>
  )> }
);

export type DeleteRedirectMutationVariables = {
  site: Scalars['ID'],
  redirect: Scalars['ID']
};


export type DeleteRedirectMutation = (
  { __typename?: 'Mutation' }
  & { deleteRedirect: (
    { __typename?: 'DeleteRedirectPayload' }
    & Pick<DeleteRedirectPayload, 'site' | 'redirect'>
  ) }
);

export type SiteFragment = (
  { __typename?: 'Site' }
  & Pick<Site, 'id' | 'code' | 'name' | 'domain' | 'otherDomains'>
);

export type GetSiteQueryVariables = {
  site: Scalars['ID']
};


export type GetSiteQuery = (
  { __typename?: 'Query' }
  & { site: (
    { __typename?: 'Site' }
    & SiteFragment
  ) }
);

export type CreateSiteMutationVariables = {
  input: CreateSiteInput
};


export type CreateSiteMutation = (
  { __typename?: 'Mutation' }
  & { createSite: (
    { __typename?: 'Site' }
    & SiteFragment
  ) }
);

export type UpdateSiteMutationVariables = {
  site: Scalars['ID'],
  input: UpdateSiteInput
};


export type UpdateSiteMutation = (
  { __typename?: 'Mutation' }
  & { updateSite: (
    { __typename?: 'Site' }
    & SiteFragment
  ) }
);

export type GetSiteSettingsQueryVariables = {
  site: Scalars['ID']
};


export type GetSiteSettingsQuery = (
  { __typename?: 'Query' }
  & { site: (
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'name' | 'settings'>
  ) }
);

export type SaveSiteSettingsMutationVariables = {
  site: Scalars['ID'],
  input: SiteSettingsInput
};


export type SaveSiteSettingsMutation = (
  { __typename?: 'Mutation' }
  & { saveSiteSettings: (
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'name'>
  ) }
);

export type GetSitesQueryVariables = {};


export type GetSitesQuery = (
  { __typename?: 'Query' }
  & { sites: Array<(
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'code' | 'domain' | 'name'>
  )> }
);

export type GetThemeFileTreeQueryVariables = {
  site: Scalars['ID']
};


export type GetThemeFileTreeQuery = (
  { __typename?: 'Query' }
  & { themeFileTree: (
    { __typename?: 'ThemeFileTree' }
    & Pick<ThemeFileTree, 'id' | 'siteId' | 'files'>
  ) }
);

export type SaveThemeFileMutationVariables = {
  site: Scalars['ID'],
  path: Scalars['String'],
  content: Scalars['String']
};


export type SaveThemeFileMutation = (
  { __typename?: 'Mutation' }
  & { saveThemeFile: Maybe<(
    { __typename?: 'SaveThemeFilePayload' }
    & Pick<SaveThemeFilePayload, 'path'>
  )> }
);

export type UserSiteFieldsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'username' | 'isSuperUser'>
  & { sites: Array<(
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'name'>
  )> }
);

export type GetUserAndSitesQueryVariables = {
  id: Scalars['ID'],
  isNew?: Maybe<Scalars['Boolean']>
};


export type GetUserAndSitesQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & UserSiteFieldsFragment
  ), sites: Array<(
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'name'>
  )> }
);

export type CreateUserMutationVariables = {
  user: CreateUserInput
};


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'CreateUserPayload' }
    & { user: Maybe<(
      { __typename?: 'User' }
      & UserSiteFieldsFragment
    )> }
  ) }
);

export type UpdateUserMutationVariables = {
  id: Scalars['ID'],
  user: UpdateUserInput
};


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UpdateUserPayload' }
    & { user: Maybe<(
      { __typename?: 'User' }
      & UserSiteFieldsFragment
    )> }
  ) }
);

export type GetUserListQueryVariables = {};


export type GetUserListQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'isSuperUser'>
  )> }
);

export type UploadFileMutationVariables = {
  file: Scalars['Upload']
};


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & { singleUpload: (
    { __typename?: 'File' }
    & Pick<File, 'filename' | 'mimetype' | 'encoding'>
  ) }
);

export const BlogPostFieldsFragmentDoc = gql`
    fragment BlogPostFields on BlogPost {
  id
  slug
  title
  content
  tags
  isPublished
  heroImage
  author {
    id
    name
    email
    photo
    url
  }
  seo {
    title
    keywords
    description
  }
}
    `;
export const BlogAuthorFieldsFragmentDoc = gql`
    fragment BlogAuthorFields on BlogPostAuthor {
  id
  name
  email
  title
  photo
  url
}
    `;
export const ProductFieldsFragmentDoc = gql`
    fragment ProductFields on Product {
  name
  description
  shippingType
  taxable
  slug
  image
  images {
    url
  }
  seo {
    title
    keywords
    description
  }
  price
  attributes {
    id
    name
    choices {
      id
      label
      price
    }
    required
  }
  enabled
}
    `;
export const EventFieldsFragmentDoc = gql`
    fragment EventFields on Event {
  id
  title
  description
}
    `;
export const ItemStuffFragmentDoc = gql`
    fragment ItemStuff on FoodMenuItem {
  id
  title
  description
  price
  photo
  additional {
    label
    value
  }
  images {
    thumbnail
  }
}
    `;
export const FormFieldsFragmentDoc = gql`
    fragment FormFields on Form {
  name
  fields
  notify
  followUp
  buttonText
  createdAt
  updatedAt
}
    `;
export const ItemFieldsFragmentDoc = gql`
    fragment ItemFields on MenuItem {
  id
  label
  url
}
    `;
export const MenuFieldsFragmentDoc = gql`
    fragment MenuFields on Menu {
  id
  name
  items {
    ...ItemFields
    children {
      ...ItemFields
      children {
        ...ItemFields
      }
    }
  }
}
    ${ItemFieldsFragmentDoc}`;
export const PageFieldsFragmentDoc = gql`
    fragment PageFields on Page {
  name
  content
  url
  seo {
    title
    keywords
    description
    isIndexable
  }
  enabled
  isTemplate
  createdAt
  updatedAt
}
    `;
export const RedirectFragmentDoc = gql`
    fragment redirect on Redirect {
  id
  siteId
  source
  dest
  redirectType
}
    `;
export const SiteFragmentDoc = gql`
    fragment site on Site {
  id
  code
  name
  domain
  otherDomains
}
    `;
export const UserSiteFieldsFragmentDoc = gql`
    fragment userSiteFields on User {
  id
  username
  sites {
    id
    name
  }
  isSuperUser
}
    `;
export const BlogPostDocument = gql`
    query BlogPost($site: ID!, $post: ID!) {
  blogPost(site: $site, post: $post) {
    ...BlogPostFields
  }
}
    ${BlogPostFieldsFragmentDoc}`;

/**
 * __useBlogPostQuery__
 *
 * To run a query within a React component, call `useBlogPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogPostQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogPostQuery({
 *   variables: {
 *      site: // value for 'site'
 *      post: // value for 'post'
 *   },
 * });
 */
export function useBlogPostQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BlogPostQuery, BlogPostQueryVariables>) {
        return ApolloReactHooks.useQuery<BlogPostQuery, BlogPostQueryVariables>(BlogPostDocument, baseOptions);
      }
export function useBlogPostLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BlogPostQuery, BlogPostQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BlogPostQuery, BlogPostQueryVariables>(BlogPostDocument, baseOptions);
        }
export type BlogPostQueryHookResult = ReturnType<typeof useBlogPostQuery>;
export type BlogPostLazyQueryHookResult = ReturnType<typeof useBlogPostLazyQuery>;
export type BlogPostQueryResult = ApolloReactCommon.QueryResult<BlogPostQuery, BlogPostQueryVariables>;
export const GetBlogPostsDocument = gql`
    query GetBlogPosts($site: ID!) {
  blogPosts(site: $site) {
    totalCount
    edges {
      node {
        id
        slug
        url
        title
        tags
        isPublished
        author {
          name
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useGetBlogPostsQuery__
 *
 * To run a query within a React component, call `useGetBlogPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogPostsQuery({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useGetBlogPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBlogPostsQuery, GetBlogPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBlogPostsQuery, GetBlogPostsQueryVariables>(GetBlogPostsDocument, baseOptions);
      }
export function useGetBlogPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBlogPostsQuery, GetBlogPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBlogPostsQuery, GetBlogPostsQueryVariables>(GetBlogPostsDocument, baseOptions);
        }
export type GetBlogPostsQueryHookResult = ReturnType<typeof useGetBlogPostsQuery>;
export type GetBlogPostsLazyQueryHookResult = ReturnType<typeof useGetBlogPostsLazyQuery>;
export type GetBlogPostsQueryResult = ApolloReactCommon.QueryResult<GetBlogPostsQuery, GetBlogPostsQueryVariables>;
export const GetBlogPostAuthorsDocument = gql`
    query GetBlogPostAuthors($site: ID!) {
  blogPostAuthors(site: $site) {
    ...BlogAuthorFields
  }
}
    ${BlogAuthorFieldsFragmentDoc}`;

/**
 * __useGetBlogPostAuthorsQuery__
 *
 * To run a query within a React component, call `useGetBlogPostAuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogPostAuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogPostAuthorsQuery({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useGetBlogPostAuthorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBlogPostAuthorsQuery, GetBlogPostAuthorsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBlogPostAuthorsQuery, GetBlogPostAuthorsQueryVariables>(GetBlogPostAuthorsDocument, baseOptions);
      }
export function useGetBlogPostAuthorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBlogPostAuthorsQuery, GetBlogPostAuthorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBlogPostAuthorsQuery, GetBlogPostAuthorsQueryVariables>(GetBlogPostAuthorsDocument, baseOptions);
        }
export type GetBlogPostAuthorsQueryHookResult = ReturnType<typeof useGetBlogPostAuthorsQuery>;
export type GetBlogPostAuthorsLazyQueryHookResult = ReturnType<typeof useGetBlogPostAuthorsLazyQuery>;
export type GetBlogPostAuthorsQueryResult = ApolloReactCommon.QueryResult<GetBlogPostAuthorsQuery, GetBlogPostAuthorsQueryVariables>;
export const BlogPostAuthorDocument = gql`
    query BlogPostAuthor($site: ID!, $author: ID!) {
  blogPostAuthor(site: $site, author: $author) {
    ...BlogAuthorFields
  }
}
    ${BlogAuthorFieldsFragmentDoc}`;

/**
 * __useBlogPostAuthorQuery__
 *
 * To run a query within a React component, call `useBlogPostAuthorQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogPostAuthorQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogPostAuthorQuery({
 *   variables: {
 *      site: // value for 'site'
 *      author: // value for 'author'
 *   },
 * });
 */
export function useBlogPostAuthorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BlogPostAuthorQuery, BlogPostAuthorQueryVariables>) {
        return ApolloReactHooks.useQuery<BlogPostAuthorQuery, BlogPostAuthorQueryVariables>(BlogPostAuthorDocument, baseOptions);
      }
export function useBlogPostAuthorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BlogPostAuthorQuery, BlogPostAuthorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BlogPostAuthorQuery, BlogPostAuthorQueryVariables>(BlogPostAuthorDocument, baseOptions);
        }
export type BlogPostAuthorQueryHookResult = ReturnType<typeof useBlogPostAuthorQuery>;
export type BlogPostAuthorLazyQueryHookResult = ReturnType<typeof useBlogPostAuthorLazyQuery>;
export type BlogPostAuthorQueryResult = ApolloReactCommon.QueryResult<BlogPostAuthorQuery, BlogPostAuthorQueryVariables>;
export const CreateBlogPostDocument = gql`
    mutation CreateBlogPost($site: ID!, $input: CreateBlogPostInput!) {
  createBlogPost(site: $site, input: $input) {
    blogPost {
      ...BlogPostFields
    }
  }
}
    ${BlogPostFieldsFragmentDoc}`;
export type CreateBlogPostMutationFn = ApolloReactCommon.MutationFunction<CreateBlogPostMutation, CreateBlogPostMutationVariables>;

/**
 * __useCreateBlogPostMutation__
 *
 * To run a mutation, you first call `useCreateBlogPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlogPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlogPostMutation, { data, loading, error }] = useCreateBlogPostMutation({
 *   variables: {
 *      site: // value for 'site'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBlogPostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBlogPostMutation, CreateBlogPostMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBlogPostMutation, CreateBlogPostMutationVariables>(CreateBlogPostDocument, baseOptions);
      }
export type CreateBlogPostMutationHookResult = ReturnType<typeof useCreateBlogPostMutation>;
export type CreateBlogPostMutationResult = ApolloReactCommon.MutationResult<CreateBlogPostMutation>;
export type CreateBlogPostMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBlogPostMutation, CreateBlogPostMutationVariables>;
export const UpdateBlogPostDocument = gql`
    mutation UpdateBlogPost($site: ID!, $id: ID!, $input: UpdateBlogPostInput!) {
  updateBlogPost(site: $site, id: $id, input: $input) {
    blogPost {
      ...BlogPostFields
    }
  }
}
    ${BlogPostFieldsFragmentDoc}`;
export type UpdateBlogPostMutationFn = ApolloReactCommon.MutationFunction<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>;

/**
 * __useUpdateBlogPostMutation__
 *
 * To run a mutation, you first call `useUpdateBlogPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlogPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlogPostMutation, { data, loading, error }] = useUpdateBlogPostMutation({
 *   variables: {
 *      site: // value for 'site'
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBlogPostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>(UpdateBlogPostDocument, baseOptions);
      }
export type UpdateBlogPostMutationHookResult = ReturnType<typeof useUpdateBlogPostMutation>;
export type UpdateBlogPostMutationResult = ApolloReactCommon.MutationResult<UpdateBlogPostMutation>;
export type UpdateBlogPostMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>;
export const UploadBlogImageDocument = gql`
    mutation UploadBlogImage($site: ID!, $image: Upload!) {
  uploadImage(site: $site, image: $image) {
    url
  }
}
    `;
export type UploadBlogImageMutationFn = ApolloReactCommon.MutationFunction<UploadBlogImageMutation, UploadBlogImageMutationVariables>;

/**
 * __useUploadBlogImageMutation__
 *
 * To run a mutation, you first call `useUploadBlogImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBlogImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBlogImageMutation, { data, loading, error }] = useUploadBlogImageMutation({
 *   variables: {
 *      site: // value for 'site'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUploadBlogImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadBlogImageMutation, UploadBlogImageMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadBlogImageMutation, UploadBlogImageMutationVariables>(UploadBlogImageDocument, baseOptions);
      }
export type UploadBlogImageMutationHookResult = ReturnType<typeof useUploadBlogImageMutation>;
export type UploadBlogImageMutationResult = ApolloReactCommon.MutationResult<UploadBlogImageMutation>;
export type UploadBlogImageMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadBlogImageMutation, UploadBlogImageMutationVariables>;
export const DeleteBlogPostDocument = gql`
    mutation DeleteBlogPost($site: ID!, $id: ID!) {
  deleteBlogPost(site: $site, id: $id) {
    site
    post
  }
}
    `;
export type DeleteBlogPostMutationFn = ApolloReactCommon.MutationFunction<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>;

/**
 * __useDeleteBlogPostMutation__
 *
 * To run a mutation, you first call `useDeleteBlogPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlogPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlogPostMutation, { data, loading, error }] = useDeleteBlogPostMutation({
 *   variables: {
 *      site: // value for 'site'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBlogPostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>(DeleteBlogPostDocument, baseOptions);
      }
export type DeleteBlogPostMutationHookResult = ReturnType<typeof useDeleteBlogPostMutation>;
export type DeleteBlogPostMutationResult = ApolloReactCommon.MutationResult<DeleteBlogPostMutation>;
export type DeleteBlogPostMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>;
export const CreateBlogAuthorDocument = gql`
    mutation CreateBlogAuthor($site: ID!, $input: CreateBlogAuthorInput!) {
  createBlogAuthor(site: $site, input: $input) {
    blogAuthor {
      ...BlogAuthorFields
    }
  }
}
    ${BlogAuthorFieldsFragmentDoc}`;
export type CreateBlogAuthorMutationFn = ApolloReactCommon.MutationFunction<CreateBlogAuthorMutation, CreateBlogAuthorMutationVariables>;

/**
 * __useCreateBlogAuthorMutation__
 *
 * To run a mutation, you first call `useCreateBlogAuthorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlogAuthorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlogAuthorMutation, { data, loading, error }] = useCreateBlogAuthorMutation({
 *   variables: {
 *      site: // value for 'site'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBlogAuthorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBlogAuthorMutation, CreateBlogAuthorMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBlogAuthorMutation, CreateBlogAuthorMutationVariables>(CreateBlogAuthorDocument, baseOptions);
      }
export type CreateBlogAuthorMutationHookResult = ReturnType<typeof useCreateBlogAuthorMutation>;
export type CreateBlogAuthorMutationResult = ApolloReactCommon.MutationResult<CreateBlogAuthorMutation>;
export type CreateBlogAuthorMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBlogAuthorMutation, CreateBlogAuthorMutationVariables>;
export const UpdateBlogAuthorDocument = gql`
    mutation UpdateBlogAuthor($site: ID!, $id: ID!, $input: UpdateBlogAuthorInput!) {
  updateBlogAuthor(site: $site, id: $id, input: $input) {
    blogAuthor {
      ...BlogAuthorFields
    }
  }
}
    ${BlogAuthorFieldsFragmentDoc}`;
export type UpdateBlogAuthorMutationFn = ApolloReactCommon.MutationFunction<UpdateBlogAuthorMutation, UpdateBlogAuthorMutationVariables>;

/**
 * __useUpdateBlogAuthorMutation__
 *
 * To run a mutation, you first call `useUpdateBlogAuthorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlogAuthorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlogAuthorMutation, { data, loading, error }] = useUpdateBlogAuthorMutation({
 *   variables: {
 *      site: // value for 'site'
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBlogAuthorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBlogAuthorMutation, UpdateBlogAuthorMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBlogAuthorMutation, UpdateBlogAuthorMutationVariables>(UpdateBlogAuthorDocument, baseOptions);
      }
export type UpdateBlogAuthorMutationHookResult = ReturnType<typeof useUpdateBlogAuthorMutation>;
export type UpdateBlogAuthorMutationResult = ApolloReactCommon.MutationResult<UpdateBlogAuthorMutation>;
export type UpdateBlogAuthorMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBlogAuthorMutation, UpdateBlogAuthorMutationVariables>;
export const CommerceSettingsDocument = gql`
    query CommerceSettings($site: ID!) {
  commerceSettings(site: $site) {
    tableRate {
      min
      max
      rate
    }
  }
}
    `;

/**
 * __useCommerceSettingsQuery__
 *
 * To run a query within a React component, call `useCommerceSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceSettingsQuery({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useCommerceSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CommerceSettingsQuery, CommerceSettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<CommerceSettingsQuery, CommerceSettingsQueryVariables>(CommerceSettingsDocument, baseOptions);
      }
export function useCommerceSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommerceSettingsQuery, CommerceSettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CommerceSettingsQuery, CommerceSettingsQueryVariables>(CommerceSettingsDocument, baseOptions);
        }
export type CommerceSettingsQueryHookResult = ReturnType<typeof useCommerceSettingsQuery>;
export type CommerceSettingsLazyQueryHookResult = ReturnType<typeof useCommerceSettingsLazyQuery>;
export type CommerceSettingsQueryResult = ApolloReactCommon.QueryResult<CommerceSettingsQuery, CommerceSettingsQueryVariables>;
export const SaveCommerceSettingsDocument = gql`
    mutation SaveCommerceSettings($site: ID!, $settings: CommerceSettingsInput!) {
  saveCommerceSettings(site: $site, input: $settings) {
    tableRate {
      min
      max
      rate
    }
  }
}
    `;
export type SaveCommerceSettingsMutationFn = ApolloReactCommon.MutationFunction<SaveCommerceSettingsMutation, SaveCommerceSettingsMutationVariables>;

/**
 * __useSaveCommerceSettingsMutation__
 *
 * To run a mutation, you first call `useSaveCommerceSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCommerceSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCommerceSettingsMutation, { data, loading, error }] = useSaveCommerceSettingsMutation({
 *   variables: {
 *      site: // value for 'site'
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useSaveCommerceSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveCommerceSettingsMutation, SaveCommerceSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveCommerceSettingsMutation, SaveCommerceSettingsMutationVariables>(SaveCommerceSettingsDocument, baseOptions);
      }
export type SaveCommerceSettingsMutationHookResult = ReturnType<typeof useSaveCommerceSettingsMutation>;
export type SaveCommerceSettingsMutationResult = ApolloReactCommon.MutationResult<SaveCommerceSettingsMutation>;
export type SaveCommerceSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveCommerceSettingsMutation, SaveCommerceSettingsMutationVariables>;
export const GetOrderDetailsDocument = gql`
    query GetOrderDetails($site: ID!, $id: ID!) {
  order(site: $site, id: $id) {
    id
    status
    total
    shipping
    taxes
    billingAddress
    shippingAddress
    customer {
      id
      name
      email
    }
    items {
      id
      product {
        id
        name
      }
      attributes {
        name
        selection
      }
      total
      deliverables
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetOrderDetailsQuery__
 *
 * To run a query within a React component, call `useGetOrderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderDetailsQuery({
 *   variables: {
 *      site: // value for 'site'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrderDetailsQuery, GetOrderDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOrderDetailsQuery, GetOrderDetailsQueryVariables>(GetOrderDetailsDocument, baseOptions);
      }
export function useGetOrderDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrderDetailsQuery, GetOrderDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOrderDetailsQuery, GetOrderDetailsQueryVariables>(GetOrderDetailsDocument, baseOptions);
        }
export type GetOrderDetailsQueryHookResult = ReturnType<typeof useGetOrderDetailsQuery>;
export type GetOrderDetailsLazyQueryHookResult = ReturnType<typeof useGetOrderDetailsLazyQuery>;
export type GetOrderDetailsQueryResult = ApolloReactCommon.QueryResult<GetOrderDetailsQuery, GetOrderDetailsQueryVariables>;
export const GetOrdersDocument = gql`
    query GetOrders($site: ID!) {
  orders(site: $site) {
    id
    total
    status
    customer {
      id
      name
      email
    }
    createdAt
  }
}
    `;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, baseOptions);
      }
export function useGetOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, baseOptions);
        }
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = ApolloReactCommon.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export const UploadProductImageDocument = gql`
    mutation UploadProductImage($site: ID!, $image: Upload!) {
  uploadProductImage(site: $site, image: $image) {
    url
  }
}
    `;
export type UploadProductImageMutationFn = ApolloReactCommon.MutationFunction<UploadProductImageMutation, UploadProductImageMutationVariables>;

/**
 * __useUploadProductImageMutation__
 *
 * To run a mutation, you first call `useUploadProductImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProductImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProductImageMutation, { data, loading, error }] = useUploadProductImageMutation({
 *   variables: {
 *      site: // value for 'site'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUploadProductImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadProductImageMutation, UploadProductImageMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadProductImageMutation, UploadProductImageMutationVariables>(UploadProductImageDocument, baseOptions);
      }
export type UploadProductImageMutationHookResult = ReturnType<typeof useUploadProductImageMutation>;
export type UploadProductImageMutationResult = ApolloReactCommon.MutationResult<UploadProductImageMutation>;
export type UploadProductImageMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadProductImageMutation, UploadProductImageMutationVariables>;
export const GetProductDocument = gql`
    query GetProduct($site: ID!, $id: ID!) {
  product(site: $site, id: $id) {
    id
    ...ProductFields
  }
}
    ${ProductFieldsFragmentDoc}`;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      site: // value for 'site'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, baseOptions);
      }
export function useGetProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, baseOptions);
        }
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<typeof useGetProductLazyQuery>;
export type GetProductQueryResult = ApolloReactCommon.QueryResult<GetProductQuery, GetProductQueryVariables>;
export const CreateProductDocument = gql`
    mutation CreateProduct($site: ID!, $product: CreateProductInput!) {
  createProduct(site: $site, product: $product) {
    product {
      id
      ...ProductFields
    }
  }
}
    ${ProductFieldsFragmentDoc}`;
export type CreateProductMutationFn = ApolloReactCommon.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      site: // value for 'site'
 *      product: // value for 'product'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, baseOptions);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = ApolloReactCommon.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($site: ID!, $product: UpdateProductInput!) {
  updateProduct(site: $site, product: $product) {
    product {
      id
      ...ProductFields
    }
  }
}
    ${ProductFieldsFragmentDoc}`;
export type UpdateProductMutationFn = ApolloReactCommon.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      site: // value for 'site'
 *      product: // value for 'product'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, baseOptions);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = ApolloReactCommon.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const GetProductsDocument = gql`
    query GetProducts($site: ID!) {
  products(site: $site) {
    id
    name
    taxable
    shippingType
    enabled
  }
}
    `;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, baseOptions);
      }
export function useGetProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, baseOptions);
        }
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = ApolloReactCommon.QueryResult<GetProductsQuery, GetProductsQueryVariables>;
export const DeleteProductDocument = gql`
    mutation DeleteProduct($site: ID!, $product: ID!) {
  deleteProduct(site: $site, product: $product) {
    site
    product
  }
}
    `;
export type DeleteProductMutationFn = ApolloReactCommon.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      site: // value for 'site'
 *      product: // value for 'product'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, baseOptions);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = ApolloReactCommon.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;
export const EnableProductDocument = gql`
    mutation EnableProduct($site: ID!, $product: ID!) {
  enableProduct(site: $site, product: $product) {
    product {
      id
      enabled
    }
  }
}
    `;
export type EnableProductMutationFn = ApolloReactCommon.MutationFunction<EnableProductMutation, EnableProductMutationVariables>;

/**
 * __useEnableProductMutation__
 *
 * To run a mutation, you first call `useEnableProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableProductMutation, { data, loading, error }] = useEnableProductMutation({
 *   variables: {
 *      site: // value for 'site'
 *      product: // value for 'product'
 *   },
 * });
 */
export function useEnableProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EnableProductMutation, EnableProductMutationVariables>) {
        return ApolloReactHooks.useMutation<EnableProductMutation, EnableProductMutationVariables>(EnableProductDocument, baseOptions);
      }
export type EnableProductMutationHookResult = ReturnType<typeof useEnableProductMutation>;
export type EnableProductMutationResult = ApolloReactCommon.MutationResult<EnableProductMutation>;
export type EnableProductMutationOptions = ApolloReactCommon.BaseMutationOptions<EnableProductMutation, EnableProductMutationVariables>;
export const DisableProductDocument = gql`
    mutation DisableProduct($site: ID!, $product: ID!) {
  disableProduct(site: $site, product: $product) {
    product {
      id
      enabled
    }
  }
}
    `;
export type DisableProductMutationFn = ApolloReactCommon.MutationFunction<DisableProductMutation, DisableProductMutationVariables>;

/**
 * __useDisableProductMutation__
 *
 * To run a mutation, you first call `useDisableProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableProductMutation, { data, loading, error }] = useDisableProductMutation({
 *   variables: {
 *      site: // value for 'site'
 *      product: // value for 'product'
 *   },
 * });
 */
export function useDisableProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisableProductMutation, DisableProductMutationVariables>) {
        return ApolloReactHooks.useMutation<DisableProductMutation, DisableProductMutationVariables>(DisableProductDocument, baseOptions);
      }
export type DisableProductMutationHookResult = ReturnType<typeof useDisableProductMutation>;
export type DisableProductMutationResult = ApolloReactCommon.MutationResult<DisableProductMutation>;
export type DisableProductMutationOptions = ApolloReactCommon.BaseMutationOptions<DisableProductMutation, DisableProductMutationVariables>;
export const GetProductIdsAndNamesDocument = gql`
    query GetProductIdsAndNames($site: ID!) {
  products(site: $site) {
    id
    name
  }
}
    `;

/**
 * __useGetProductIdsAndNamesQuery__
 *
 * To run a query within a React component, call `useGetProductIdsAndNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductIdsAndNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductIdsAndNamesQuery({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useGetProductIdsAndNamesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductIdsAndNamesQuery, GetProductIdsAndNamesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductIdsAndNamesQuery, GetProductIdsAndNamesQueryVariables>(GetProductIdsAndNamesDocument, baseOptions);
      }
export function useGetProductIdsAndNamesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductIdsAndNamesQuery, GetProductIdsAndNamesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductIdsAndNamesQuery, GetProductIdsAndNamesQueryVariables>(GetProductIdsAndNamesDocument, baseOptions);
        }
export type GetProductIdsAndNamesQueryHookResult = ReturnType<typeof useGetProductIdsAndNamesQuery>;
export type GetProductIdsAndNamesLazyQueryHookResult = ReturnType<typeof useGetProductIdsAndNamesLazyQuery>;
export type GetProductIdsAndNamesQueryResult = ApolloReactCommon.QueryResult<GetProductIdsAndNamesQuery, GetProductIdsAndNamesQueryVariables>;
export const SalesReportDocument = gql`
    query SalesReport($site: ID!, $range: String, $dates: String, $products: [ID!]) {
  salesReport(site: $site, range: $range, dates: $dates, products: $products) {
    year
    month
    day
    sales
    total
  }
}
    `;

/**
 * __useSalesReportQuery__
 *
 * To run a query within a React component, call `useSalesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesReportQuery({
 *   variables: {
 *      site: // value for 'site'
 *      range: // value for 'range'
 *      dates: // value for 'dates'
 *      products: // value for 'products'
 *   },
 * });
 */
export function useSalesReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SalesReportQuery, SalesReportQueryVariables>) {
        return ApolloReactHooks.useQuery<SalesReportQuery, SalesReportQueryVariables>(SalesReportDocument, baseOptions);
      }
export function useSalesReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SalesReportQuery, SalesReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SalesReportQuery, SalesReportQueryVariables>(SalesReportDocument, baseOptions);
        }
export type SalesReportQueryHookResult = ReturnType<typeof useSalesReportQuery>;
export type SalesReportLazyQueryHookResult = ReturnType<typeof useSalesReportLazyQuery>;
export type SalesReportQueryResult = ApolloReactCommon.QueryResult<SalesReportQuery, SalesReportQueryVariables>;
export const TaxReportDocument = gql`
    query TaxReport($site: ID!, $range: String, $dates: String) {
  taxReport(site: $site, range: $range, dates: $dates) {
    year
    month
    day
    sales
    total
    taxes
    shipping
  }
}
    `;

/**
 * __useTaxReportQuery__
 *
 * To run a query within a React component, call `useTaxReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxReportQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxReportQuery({
 *   variables: {
 *      site: // value for 'site'
 *      range: // value for 'range'
 *      dates: // value for 'dates'
 *   },
 * });
 */
export function useTaxReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TaxReportQuery, TaxReportQueryVariables>) {
        return ApolloReactHooks.useQuery<TaxReportQuery, TaxReportQueryVariables>(TaxReportDocument, baseOptions);
      }
export function useTaxReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TaxReportQuery, TaxReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TaxReportQuery, TaxReportQueryVariables>(TaxReportDocument, baseOptions);
        }
export type TaxReportQueryHookResult = ReturnType<typeof useTaxReportQuery>;
export type TaxReportLazyQueryHookResult = ReturnType<typeof useTaxReportLazyQuery>;
export type TaxReportQueryResult = ApolloReactCommon.QueryResult<TaxReportQuery, TaxReportQueryVariables>;
export const GetEventDocument = gql`
    query GetEvent($site: ID!, $id: ID!) {
  event(site: $site, id: $id) {
    ...EventFields
  }
}
    ${EventFieldsFragmentDoc}`;

/**
 * __useGetEventQuery__
 *
 * To run a query within a React component, call `useGetEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventQuery({
 *   variables: {
 *      site: // value for 'site'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, baseOptions);
      }
export function useGetEventLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, baseOptions);
        }
export type GetEventQueryHookResult = ReturnType<typeof useGetEventQuery>;
export type GetEventLazyQueryHookResult = ReturnType<typeof useGetEventLazyQuery>;
export type GetEventQueryResult = ApolloReactCommon.QueryResult<GetEventQuery, GetEventQueryVariables>;
export const GetEventListDocument = gql`
    query GetEventList($site: ID!, $first: Int, $skip: Int) {
  events(site: $site, first: $first, skip: $skip) {
    edges {
      node {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useGetEventListQuery__
 *
 * To run a query within a React component, call `useGetEventListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventListQuery({
 *   variables: {
 *      site: // value for 'site'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetEventListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEventListQuery, GetEventListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEventListQuery, GetEventListQueryVariables>(GetEventListDocument, baseOptions);
      }
export function useGetEventListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEventListQuery, GetEventListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEventListQuery, GetEventListQueryVariables>(GetEventListDocument, baseOptions);
        }
export type GetEventListQueryHookResult = ReturnType<typeof useGetEventListQuery>;
export type GetEventListLazyQueryHookResult = ReturnType<typeof useGetEventListLazyQuery>;
export type GetEventListQueryResult = ApolloReactCommon.QueryResult<GetEventListQuery, GetEventListQueryVariables>;
export const UploadThumbnailDocument = gql`
    mutation UploadThumbnail($site: ID!, $image: Upload!) {
  uploadFoodItemThumbnail(site: $site, image: $image) {
    url
  }
}
    `;
export type UploadThumbnailMutationFn = ApolloReactCommon.MutationFunction<UploadThumbnailMutation, UploadThumbnailMutationVariables>;

/**
 * __useUploadThumbnailMutation__
 *
 * To run a mutation, you first call `useUploadThumbnailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadThumbnailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadThumbnailMutation, { data, loading, error }] = useUploadThumbnailMutation({
 *   variables: {
 *      site: // value for 'site'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUploadThumbnailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadThumbnailMutation, UploadThumbnailMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadThumbnailMutation, UploadThumbnailMutationVariables>(UploadThumbnailDocument, baseOptions);
      }
export type UploadThumbnailMutationHookResult = ReturnType<typeof useUploadThumbnailMutation>;
export type UploadThumbnailMutationResult = ApolloReactCommon.MutationResult<UploadThumbnailMutation>;
export type UploadThumbnailMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadThumbnailMutation, UploadThumbnailMutationVariables>;
export const GetFoodMenuDocument = gql`
    query GetFoodMenu($site: ID!, $id: ID!) {
  foodMenu(site: $site, id: $id) {
    name
    sections {
      id
      title
      description
      items {
        ...ItemStuff
      }
    }
  }
}
    ${ItemStuffFragmentDoc}`;

/**
 * __useGetFoodMenuQuery__
 *
 * To run a query within a React component, call `useGetFoodMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFoodMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFoodMenuQuery({
 *   variables: {
 *      site: // value for 'site'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFoodMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFoodMenuQuery, GetFoodMenuQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFoodMenuQuery, GetFoodMenuQueryVariables>(GetFoodMenuDocument, baseOptions);
      }
export function useGetFoodMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFoodMenuQuery, GetFoodMenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFoodMenuQuery, GetFoodMenuQueryVariables>(GetFoodMenuDocument, baseOptions);
        }
export type GetFoodMenuQueryHookResult = ReturnType<typeof useGetFoodMenuQuery>;
export type GetFoodMenuLazyQueryHookResult = ReturnType<typeof useGetFoodMenuLazyQuery>;
export type GetFoodMenuQueryResult = ApolloReactCommon.QueryResult<GetFoodMenuQuery, GetFoodMenuQueryVariables>;
export const CreateFoodMenuDocument = gql`
    mutation CreateFoodMenu($site: ID!, $input: CreateFoodMenuInput!) {
  createFoodMenu(site: $site, input: $input) {
    foodMenu {
      id
      name
      sections {
        id
        title
        description
        items {
          ...ItemStuff
        }
      }
    }
  }
}
    ${ItemStuffFragmentDoc}`;
export type CreateFoodMenuMutationFn = ApolloReactCommon.MutationFunction<CreateFoodMenuMutation, CreateFoodMenuMutationVariables>;

/**
 * __useCreateFoodMenuMutation__
 *
 * To run a mutation, you first call `useCreateFoodMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFoodMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFoodMenuMutation, { data, loading, error }] = useCreateFoodMenuMutation({
 *   variables: {
 *      site: // value for 'site'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFoodMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFoodMenuMutation, CreateFoodMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFoodMenuMutation, CreateFoodMenuMutationVariables>(CreateFoodMenuDocument, baseOptions);
      }
export type CreateFoodMenuMutationHookResult = ReturnType<typeof useCreateFoodMenuMutation>;
export type CreateFoodMenuMutationResult = ApolloReactCommon.MutationResult<CreateFoodMenuMutation>;
export type CreateFoodMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFoodMenuMutation, CreateFoodMenuMutationVariables>;
export const UpdateFoodMenuDocument = gql`
    mutation UpdateFoodMenu($site: ID!, $id: ID!, $input: UpdateFoodMenuInput!) {
  updateFoodMenu(site: $site, id: $id, input: $input) {
    foodMenu {
      name
      sections {
        id
        title
        description
        items {
          ...ItemStuff
        }
      }
    }
  }
}
    ${ItemStuffFragmentDoc}`;
export type UpdateFoodMenuMutationFn = ApolloReactCommon.MutationFunction<UpdateFoodMenuMutation, UpdateFoodMenuMutationVariables>;

/**
 * __useUpdateFoodMenuMutation__
 *
 * To run a mutation, you first call `useUpdateFoodMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFoodMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFoodMenuMutation, { data, loading, error }] = useUpdateFoodMenuMutation({
 *   variables: {
 *      site: // value for 'site'
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFoodMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFoodMenuMutation, UpdateFoodMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateFoodMenuMutation, UpdateFoodMenuMutationVariables>(UpdateFoodMenuDocument, baseOptions);
      }
export type UpdateFoodMenuMutationHookResult = ReturnType<typeof useUpdateFoodMenuMutation>;
export type UpdateFoodMenuMutationResult = ApolloReactCommon.MutationResult<UpdateFoodMenuMutation>;
export type UpdateFoodMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFoodMenuMutation, UpdateFoodMenuMutationVariables>;
export const GetFoodMenuListDocument = gql`
    query GetFoodMenuList($site: ID!) {
  foodMenus(site: $site) {
    id
    name
  }
}
    `;

/**
 * __useGetFoodMenuListQuery__
 *
 * To run a query within a React component, call `useGetFoodMenuListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFoodMenuListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFoodMenuListQuery({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useGetFoodMenuListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFoodMenuListQuery, GetFoodMenuListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFoodMenuListQuery, GetFoodMenuListQueryVariables>(GetFoodMenuListDocument, baseOptions);
      }
export function useGetFoodMenuListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFoodMenuListQuery, GetFoodMenuListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFoodMenuListQuery, GetFoodMenuListQueryVariables>(GetFoodMenuListDocument, baseOptions);
        }
export type GetFoodMenuListQueryHookResult = ReturnType<typeof useGetFoodMenuListQuery>;
export type GetFoodMenuListLazyQueryHookResult = ReturnType<typeof useGetFoodMenuListLazyQuery>;
export type GetFoodMenuListQueryResult = ApolloReactCommon.QueryResult<GetFoodMenuListQuery, GetFoodMenuListQueryVariables>;
export const DeleteFoodMenuDocument = gql`
    mutation DeleteFoodMenu($site: ID!, $menu: ID!) {
  deleteFoodMenu(site: $site, menu: $menu) {
    site
    menu
  }
}
    `;
export type DeleteFoodMenuMutationFn = ApolloReactCommon.MutationFunction<DeleteFoodMenuMutation, DeleteFoodMenuMutationVariables>;

/**
 * __useDeleteFoodMenuMutation__
 *
 * To run a mutation, you first call `useDeleteFoodMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFoodMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFoodMenuMutation, { data, loading, error }] = useDeleteFoodMenuMutation({
 *   variables: {
 *      site: // value for 'site'
 *      menu: // value for 'menu'
 *   },
 * });
 */
export function useDeleteFoodMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFoodMenuMutation, DeleteFoodMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFoodMenuMutation, DeleteFoodMenuMutationVariables>(DeleteFoodMenuDocument, baseOptions);
      }
export type DeleteFoodMenuMutationHookResult = ReturnType<typeof useDeleteFoodMenuMutation>;
export type DeleteFoodMenuMutationResult = ApolloReactCommon.MutationResult<DeleteFoodMenuMutation>;
export type DeleteFoodMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFoodMenuMutation, DeleteFoodMenuMutationVariables>;
export const GetFormDetailsDocument = gql`
    query GetFormDetails($site: ID!, $form: ID!) {
  form(site: $site, form: $form) {
    id
    siteId
    ...FormFields
  }
}
    ${FormFieldsFragmentDoc}`;

/**
 * __useGetFormDetailsQuery__
 *
 * To run a query within a React component, call `useGetFormDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormDetailsQuery({
 *   variables: {
 *      site: // value for 'site'
 *      form: // value for 'form'
 *   },
 * });
 */
export function useGetFormDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFormDetailsQuery, GetFormDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFormDetailsQuery, GetFormDetailsQueryVariables>(GetFormDetailsDocument, baseOptions);
      }
export function useGetFormDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFormDetailsQuery, GetFormDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFormDetailsQuery, GetFormDetailsQueryVariables>(GetFormDetailsDocument, baseOptions);
        }
export type GetFormDetailsQueryHookResult = ReturnType<typeof useGetFormDetailsQuery>;
export type GetFormDetailsLazyQueryHookResult = ReturnType<typeof useGetFormDetailsLazyQuery>;
export type GetFormDetailsQueryResult = ApolloReactCommon.QueryResult<GetFormDetailsQuery, GetFormDetailsQueryVariables>;
export const CreateFormDocument = gql`
    mutation CreateForm($site: ID!, $input: CreateFormInput!) {
  createForm(site: $site, input: $input) {
    form {
      id
      siteId
      ...FormFields
    }
  }
}
    ${FormFieldsFragmentDoc}`;
export type CreateFormMutationFn = ApolloReactCommon.MutationFunction<CreateFormMutation, CreateFormMutationVariables>;

/**
 * __useCreateFormMutation__
 *
 * To run a mutation, you first call `useCreateFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormMutation, { data, loading, error }] = useCreateFormMutation({
 *   variables: {
 *      site: // value for 'site'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFormMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFormMutation, CreateFormMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFormMutation, CreateFormMutationVariables>(CreateFormDocument, baseOptions);
      }
export type CreateFormMutationHookResult = ReturnType<typeof useCreateFormMutation>;
export type CreateFormMutationResult = ApolloReactCommon.MutationResult<CreateFormMutation>;
export type CreateFormMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFormMutation, CreateFormMutationVariables>;
export const SaveFormDocument = gql`
    mutation SaveForm($site: ID!, $form: ID!, $input: SaveFormInput!) {
  saveForm(site: $site, form: $form, input: $input) {
    form {
      id
      siteId
      ...FormFields
    }
  }
}
    ${FormFieldsFragmentDoc}`;
export type SaveFormMutationFn = ApolloReactCommon.MutationFunction<SaveFormMutation, SaveFormMutationVariables>;

/**
 * __useSaveFormMutation__
 *
 * To run a mutation, you first call `useSaveFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveFormMutation, { data, loading, error }] = useSaveFormMutation({
 *   variables: {
 *      site: // value for 'site'
 *      form: // value for 'form'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveFormMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveFormMutation, SaveFormMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveFormMutation, SaveFormMutationVariables>(SaveFormDocument, baseOptions);
      }
export type SaveFormMutationHookResult = ReturnType<typeof useSaveFormMutation>;
export type SaveFormMutationResult = ApolloReactCommon.MutationResult<SaveFormMutation>;
export type SaveFormMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveFormMutation, SaveFormMutationVariables>;
export const LatestFormSubmissionsDocument = gql`
    query LatestFormSubmissions($site: ID!, $first: Int!) {
  submissionsPerDay(site: $site) {
    total
  }
  latestFormSubmissions(site: $site, first: $first) {
    id
    form {
      id
      name
    }
    teaser(maxLength: 100)
    createdAt
  }
}
    `;

/**
 * __useLatestFormSubmissionsQuery__
 *
 * To run a query within a React component, call `useLatestFormSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestFormSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestFormSubmissionsQuery({
 *   variables: {
 *      site: // value for 'site'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useLatestFormSubmissionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LatestFormSubmissionsQuery, LatestFormSubmissionsQueryVariables>) {
        return ApolloReactHooks.useQuery<LatestFormSubmissionsQuery, LatestFormSubmissionsQueryVariables>(LatestFormSubmissionsDocument, baseOptions);
      }
export function useLatestFormSubmissionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LatestFormSubmissionsQuery, LatestFormSubmissionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LatestFormSubmissionsQuery, LatestFormSubmissionsQueryVariables>(LatestFormSubmissionsDocument, baseOptions);
        }
export type LatestFormSubmissionsQueryHookResult = ReturnType<typeof useLatestFormSubmissionsQuery>;
export type LatestFormSubmissionsLazyQueryHookResult = ReturnType<typeof useLatestFormSubmissionsLazyQuery>;
export type LatestFormSubmissionsQueryResult = ApolloReactCommon.QueryResult<LatestFormSubmissionsQuery, LatestFormSubmissionsQueryVariables>;
export const GetFormsDocument = gql`
    query GetForms($site: ID!) {
  forms(site: $site) {
    id
    name
    submissionCount
    submissionsPerDay {
      total
    }
  }
}
    `;

/**
 * __useGetFormsQuery__
 *
 * To run a query within a React component, call `useGetFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormsQuery({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useGetFormsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFormsQuery, GetFormsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFormsQuery, GetFormsQueryVariables>(GetFormsDocument, baseOptions);
      }
export function useGetFormsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFormsQuery, GetFormsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFormsQuery, GetFormsQueryVariables>(GetFormsDocument, baseOptions);
        }
export type GetFormsQueryHookResult = ReturnType<typeof useGetFormsQuery>;
export type GetFormsLazyQueryHookResult = ReturnType<typeof useGetFormsLazyQuery>;
export type GetFormsQueryResult = ApolloReactCommon.QueryResult<GetFormsQuery, GetFormsQueryVariables>;
export const DeleteFormDocument = gql`
    mutation DeleteForm($site: ID!, $form: ID!) {
  deleteForm(site: $site, form: $form) {
    id
  }
}
    `;
export type DeleteFormMutationFn = ApolloReactCommon.MutationFunction<DeleteFormMutation, DeleteFormMutationVariables>;

/**
 * __useDeleteFormMutation__
 *
 * To run a mutation, you first call `useDeleteFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormMutation, { data, loading, error }] = useDeleteFormMutation({
 *   variables: {
 *      site: // value for 'site'
 *      form: // value for 'form'
 *   },
 * });
 */
export function useDeleteFormMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFormMutation, DeleteFormMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFormMutation, DeleteFormMutationVariables>(DeleteFormDocument, baseOptions);
      }
export type DeleteFormMutationHookResult = ReturnType<typeof useDeleteFormMutation>;
export type DeleteFormMutationResult = ApolloReactCommon.MutationResult<DeleteFormMutation>;
export type DeleteFormMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFormMutation, DeleteFormMutationVariables>;
export const GetFormSubmissionDocument = gql`
    query GetFormSubmission($site: ID!, $submission: ID!) {
  formSubmission(site: $site, submission: $submission) {
    id
    form {
      id
      name
    }
    submitter {
      initials
    }
    teaser
    labels
    values
    isSpam
    createdAt
  }
}
    `;

/**
 * __useGetFormSubmissionQuery__
 *
 * To run a query within a React component, call `useGetFormSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormSubmissionQuery({
 *   variables: {
 *      site: // value for 'site'
 *      submission: // value for 'submission'
 *   },
 * });
 */
export function useGetFormSubmissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFormSubmissionQuery, GetFormSubmissionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFormSubmissionQuery, GetFormSubmissionQueryVariables>(GetFormSubmissionDocument, baseOptions);
      }
export function useGetFormSubmissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFormSubmissionQuery, GetFormSubmissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFormSubmissionQuery, GetFormSubmissionQueryVariables>(GetFormSubmissionDocument, baseOptions);
        }
export type GetFormSubmissionQueryHookResult = ReturnType<typeof useGetFormSubmissionQuery>;
export type GetFormSubmissionLazyQueryHookResult = ReturnType<typeof useGetFormSubmissionLazyQuery>;
export type GetFormSubmissionQueryResult = ApolloReactCommon.QueryResult<GetFormSubmissionQuery, GetFormSubmissionQueryVariables>;
export const MarkFormAsSpamDocument = gql`
    mutation MarkFormAsSpam($site: ID!, $forms: [ID!]!) {
  markFormsAsSpam(site: $site, forms: $forms) {
    id
    isSpam
  }
}
    `;
export type MarkFormAsSpamMutationFn = ApolloReactCommon.MutationFunction<MarkFormAsSpamMutation, MarkFormAsSpamMutationVariables>;

/**
 * __useMarkFormAsSpamMutation__
 *
 * To run a mutation, you first call `useMarkFormAsSpamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkFormAsSpamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markFormAsSpamMutation, { data, loading, error }] = useMarkFormAsSpamMutation({
 *   variables: {
 *      site: // value for 'site'
 *      forms: // value for 'forms'
 *   },
 * });
 */
export function useMarkFormAsSpamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkFormAsSpamMutation, MarkFormAsSpamMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkFormAsSpamMutation, MarkFormAsSpamMutationVariables>(MarkFormAsSpamDocument, baseOptions);
      }
export type MarkFormAsSpamMutationHookResult = ReturnType<typeof useMarkFormAsSpamMutation>;
export type MarkFormAsSpamMutationResult = ApolloReactCommon.MutationResult<MarkFormAsSpamMutation>;
export type MarkFormAsSpamMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkFormAsSpamMutation, MarkFormAsSpamMutationVariables>;
export const MarkFormAsNotSpamDocument = gql`
    mutation MarkFormAsNotSpam($site: ID!, $forms: [ID!]!) {
  markFormsAsNotSpam(site: $site, forms: $forms) {
    id
    isSpam
  }
}
    `;
export type MarkFormAsNotSpamMutationFn = ApolloReactCommon.MutationFunction<MarkFormAsNotSpamMutation, MarkFormAsNotSpamMutationVariables>;

/**
 * __useMarkFormAsNotSpamMutation__
 *
 * To run a mutation, you first call `useMarkFormAsNotSpamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkFormAsNotSpamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markFormAsNotSpamMutation, { data, loading, error }] = useMarkFormAsNotSpamMutation({
 *   variables: {
 *      site: // value for 'site'
 *      forms: // value for 'forms'
 *   },
 * });
 */
export function useMarkFormAsNotSpamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkFormAsNotSpamMutation, MarkFormAsNotSpamMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkFormAsNotSpamMutation, MarkFormAsNotSpamMutationVariables>(MarkFormAsNotSpamDocument, baseOptions);
      }
export type MarkFormAsNotSpamMutationHookResult = ReturnType<typeof useMarkFormAsNotSpamMutation>;
export type MarkFormAsNotSpamMutationResult = ApolloReactCommon.MutationResult<MarkFormAsNotSpamMutation>;
export type MarkFormAsNotSpamMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkFormAsNotSpamMutation, MarkFormAsNotSpamMutationVariables>;
export const GetFormSubmissionListsDocument = gql`
    query GetFormSubmissionLists($site: ID!, $form: ID!, $teaserLength: Int = 120) {
  allForms: forms(site: $site) {
    id
    name
  }
  form(site: $site, form: $form) {
    id
    name
  }
  formSubmissions(site: $site, form: $form, includeSpam: true) {
    id
    teaser(maxLength: $teaserLength)
    submitter {
      initials
    }
    labels
    values
    isSpam
    createdAt
  }
}
    `;

/**
 * __useGetFormSubmissionListsQuery__
 *
 * To run a query within a React component, call `useGetFormSubmissionListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormSubmissionListsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormSubmissionListsQuery({
 *   variables: {
 *      site: // value for 'site'
 *      form: // value for 'form'
 *      teaserLength: // value for 'teaserLength'
 *   },
 * });
 */
export function useGetFormSubmissionListsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFormSubmissionListsQuery, GetFormSubmissionListsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFormSubmissionListsQuery, GetFormSubmissionListsQueryVariables>(GetFormSubmissionListsDocument, baseOptions);
      }
export function useGetFormSubmissionListsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFormSubmissionListsQuery, GetFormSubmissionListsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFormSubmissionListsQuery, GetFormSubmissionListsQueryVariables>(GetFormSubmissionListsDocument, baseOptions);
        }
export type GetFormSubmissionListsQueryHookResult = ReturnType<typeof useGetFormSubmissionListsQuery>;
export type GetFormSubmissionListsLazyQueryHookResult = ReturnType<typeof useGetFormSubmissionListsLazyQuery>;
export type GetFormSubmissionListsQueryResult = ApolloReactCommon.QueryResult<GetFormSubmissionListsQuery, GetFormSubmissionListsQueryVariables>;
export const GetSiteAssetsDocument = gql`
    query GetSiteAssets($site: ID!) {
  site(id: $site) {
    assets {
      id
      name
      url
      size
      dimensions {
        width
        height
      }
    }
  }
}
    `;

/**
 * __useGetSiteAssetsQuery__
 *
 * To run a query within a React component, call `useGetSiteAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteAssetsQuery({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useGetSiteAssetsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteAssetsQuery, GetSiteAssetsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteAssetsQuery, GetSiteAssetsQueryVariables>(GetSiteAssetsDocument, baseOptions);
      }
export function useGetSiteAssetsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteAssetsQuery, GetSiteAssetsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteAssetsQuery, GetSiteAssetsQueryVariables>(GetSiteAssetsDocument, baseOptions);
        }
export type GetSiteAssetsQueryHookResult = ReturnType<typeof useGetSiteAssetsQuery>;
export type GetSiteAssetsLazyQueryHookResult = ReturnType<typeof useGetSiteAssetsLazyQuery>;
export type GetSiteAssetsQueryResult = ApolloReactCommon.QueryResult<GetSiteAssetsQuery, GetSiteAssetsQueryVariables>;
export const GetSiteRoutesDocument = gql`
    query GetSiteRoutes($site: ID!) {
  routes(site: $site) {
    id
    type
    name
    url
  }
}
    `;

/**
 * __useGetSiteRoutesQuery__
 *
 * To run a query within a React component, call `useGetSiteRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteRoutesQuery({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useGetSiteRoutesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteRoutesQuery, GetSiteRoutesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteRoutesQuery, GetSiteRoutesQueryVariables>(GetSiteRoutesDocument, baseOptions);
      }
export function useGetSiteRoutesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteRoutesQuery, GetSiteRoutesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteRoutesQuery, GetSiteRoutesQueryVariables>(GetSiteRoutesDocument, baseOptions);
        }
export type GetSiteRoutesQueryHookResult = ReturnType<typeof useGetSiteRoutesQuery>;
export type GetSiteRoutesLazyQueryHookResult = ReturnType<typeof useGetSiteRoutesLazyQuery>;
export type GetSiteRoutesQueryResult = ApolloReactCommon.QueryResult<GetSiteRoutesQuery, GetSiteRoutesQueryVariables>;
export const GetMenuDocument = gql`
    query GetMenu($site: ID!, $id: ID!) {
  menu(site: $site, id: $id) {
    ...MenuFields
  }
}
    ${MenuFieldsFragmentDoc}`;

/**
 * __useGetMenuQuery__
 *
 * To run a query within a React component, call `useGetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuQuery({
 *   variables: {
 *      site: // value for 'site'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMenuQuery, GetMenuQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMenuQuery, GetMenuQueryVariables>(GetMenuDocument, baseOptions);
      }
export function useGetMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMenuQuery, GetMenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMenuQuery, GetMenuQueryVariables>(GetMenuDocument, baseOptions);
        }
export type GetMenuQueryHookResult = ReturnType<typeof useGetMenuQuery>;
export type GetMenuLazyQueryHookResult = ReturnType<typeof useGetMenuLazyQuery>;
export type GetMenuQueryResult = ApolloReactCommon.QueryResult<GetMenuQuery, GetMenuQueryVariables>;
export const CreateMenuDocument = gql`
    mutation CreateMenu($site: ID!, $menu: CreateMenuInput!) {
  createMenu(site: $site, menu: $menu) {
    errors {
      key
      message
    }
    menu {
      ...MenuFields
    }
  }
}
    ${MenuFieldsFragmentDoc}`;
export type CreateMenuMutationFn = ApolloReactCommon.MutationFunction<CreateMenuMutation, CreateMenuMutationVariables>;

/**
 * __useCreateMenuMutation__
 *
 * To run a mutation, you first call `useCreateMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMenuMutation, { data, loading, error }] = useCreateMenuMutation({
 *   variables: {
 *      site: // value for 'site'
 *      menu: // value for 'menu'
 *   },
 * });
 */
export function useCreateMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMenuMutation, CreateMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMenuMutation, CreateMenuMutationVariables>(CreateMenuDocument, baseOptions);
      }
export type CreateMenuMutationHookResult = ReturnType<typeof useCreateMenuMutation>;
export type CreateMenuMutationResult = ApolloReactCommon.MutationResult<CreateMenuMutation>;
export type CreateMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMenuMutation, CreateMenuMutationVariables>;
export const SaveMenuDocument = gql`
    mutation SaveMenu($site: ID!, $id: ID!, $menu: SaveMenuInput!) {
  saveMenu(site: $site, id: $id, menu: $menu) {
    errors {
      key
      message
    }
    menu {
      ...MenuFields
    }
  }
}
    ${MenuFieldsFragmentDoc}`;
export type SaveMenuMutationFn = ApolloReactCommon.MutationFunction<SaveMenuMutation, SaveMenuMutationVariables>;

/**
 * __useSaveMenuMutation__
 *
 * To run a mutation, you first call `useSaveMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMenuMutation, { data, loading, error }] = useSaveMenuMutation({
 *   variables: {
 *      site: // value for 'site'
 *      id: // value for 'id'
 *      menu: // value for 'menu'
 *   },
 * });
 */
export function useSaveMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveMenuMutation, SaveMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveMenuMutation, SaveMenuMutationVariables>(SaveMenuDocument, baseOptions);
      }
export type SaveMenuMutationHookResult = ReturnType<typeof useSaveMenuMutation>;
export type SaveMenuMutationResult = ApolloReactCommon.MutationResult<SaveMenuMutation>;
export type SaveMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveMenuMutation, SaveMenuMutationVariables>;
export const GetMenusDocument = gql`
    query GetMenus($site: ID!) {
  menus(site: $site) {
    id
    name
  }
}
    `;

/**
 * __useGetMenusQuery__
 *
 * To run a query within a React component, call `useGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenusQuery({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useGetMenusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMenusQuery, GetMenusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMenusQuery, GetMenusQueryVariables>(GetMenusDocument, baseOptions);
      }
export function useGetMenusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMenusQuery, GetMenusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMenusQuery, GetMenusQueryVariables>(GetMenusDocument, baseOptions);
        }
export type GetMenusQueryHookResult = ReturnType<typeof useGetMenusQuery>;
export type GetMenusLazyQueryHookResult = ReturnType<typeof useGetMenusLazyQuery>;
export type GetMenusQueryResult = ApolloReactCommon.QueryResult<GetMenusQuery, GetMenusQueryVariables>;
export const DeleteMenuDocument = gql`
    mutation DeleteMenu($site: ID!, $menu: ID!) {
  deleteMenu(site: $site, menu: $menu) {
    site
    menu
  }
}
    `;
export type DeleteMenuMutationFn = ApolloReactCommon.MutationFunction<DeleteMenuMutation, DeleteMenuMutationVariables>;

/**
 * __useDeleteMenuMutation__
 *
 * To run a mutation, you first call `useDeleteMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMenuMutation, { data, loading, error }] = useDeleteMenuMutation({
 *   variables: {
 *      site: // value for 'site'
 *      menu: // value for 'menu'
 *   },
 * });
 */
export function useDeleteMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMenuMutation, DeleteMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMenuMutation, DeleteMenuMutationVariables>(DeleteMenuDocument, baseOptions);
      }
export type DeleteMenuMutationHookResult = ReturnType<typeof useDeleteMenuMutation>;
export type DeleteMenuMutationResult = ApolloReactCommon.MutationResult<DeleteMenuMutation>;
export type DeleteMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMenuMutation, DeleteMenuMutationVariables>;
export const FoodMenuPickListDocument = gql`
    query FoodMenuPickList($site: ID!) {
  foodMenus(site: $site) {
    id
    name
  }
}
    `;

/**
 * __useFoodMenuPickListQuery__
 *
 * To run a query within a React component, call `useFoodMenuPickListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodMenuPickListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodMenuPickListQuery({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useFoodMenuPickListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FoodMenuPickListQuery, FoodMenuPickListQueryVariables>) {
        return ApolloReactHooks.useQuery<FoodMenuPickListQuery, FoodMenuPickListQueryVariables>(FoodMenuPickListDocument, baseOptions);
      }
export function useFoodMenuPickListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FoodMenuPickListQuery, FoodMenuPickListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FoodMenuPickListQuery, FoodMenuPickListQueryVariables>(FoodMenuPickListDocument, baseOptions);
        }
export type FoodMenuPickListQueryHookResult = ReturnType<typeof useFoodMenuPickListQuery>;
export type FoodMenuPickListLazyQueryHookResult = ReturnType<typeof useFoodMenuPickListLazyQuery>;
export type FoodMenuPickListQueryResult = ApolloReactCommon.QueryResult<FoodMenuPickListQuery, FoodMenuPickListQueryVariables>;
export const FormMenuPickListDocument = gql`
    query FormMenuPickList($site: ID!) {
  forms(site: $site) {
    id
    name
  }
}
    `;

/**
 * __useFormMenuPickListQuery__
 *
 * To run a query within a React component, call `useFormMenuPickListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormMenuPickListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormMenuPickListQuery({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useFormMenuPickListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FormMenuPickListQuery, FormMenuPickListQueryVariables>) {
        return ApolloReactHooks.useQuery<FormMenuPickListQuery, FormMenuPickListQueryVariables>(FormMenuPickListDocument, baseOptions);
      }
export function useFormMenuPickListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FormMenuPickListQuery, FormMenuPickListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FormMenuPickListQuery, FormMenuPickListQueryVariables>(FormMenuPickListDocument, baseOptions);
        }
export type FormMenuPickListQueryHookResult = ReturnType<typeof useFormMenuPickListQuery>;
export type FormMenuPickListLazyQueryHookResult = ReturnType<typeof useFormMenuPickListLazyQuery>;
export type FormMenuPickListQueryResult = ApolloReactCommon.QueryResult<FormMenuPickListQuery, FormMenuPickListQueryVariables>;
export const UploadImageDocument = gql`
    mutation UploadImage($site: ID!, $image: Upload!) {
  uploadImage(site: $site, image: $image) {
    url
  }
}
    `;
export type UploadImageMutationFn = ApolloReactCommon.MutationFunction<UploadImageMutation, UploadImageMutationVariables>;

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      site: // value for 'site'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUploadImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadImageMutation, UploadImageMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadImageMutation, UploadImageMutationVariables>(UploadImageDocument, baseOptions);
      }
export type UploadImageMutationHookResult = ReturnType<typeof useUploadImageMutation>;
export type UploadImageMutationResult = ApolloReactCommon.MutationResult<UploadImageMutation>;
export type UploadImageMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadImageMutation, UploadImageMutationVariables>;
export const GetPageDocument = gql`
    query GetPage($site: ID!, $page: ID!) {
  page(site: $site, page: $page) {
    id
    ...PageFields
  }
}
    ${PageFieldsFragmentDoc}`;

/**
 * __useGetPageQuery__
 *
 * To run a query within a React component, call `useGetPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageQuery({
 *   variables: {
 *      site: // value for 'site'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, baseOptions);
      }
export function useGetPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, baseOptions);
        }
export type GetPageQueryHookResult = ReturnType<typeof useGetPageQuery>;
export type GetPageLazyQueryHookResult = ReturnType<typeof useGetPageLazyQuery>;
export type GetPageQueryResult = ApolloReactCommon.QueryResult<GetPageQuery, GetPageQueryVariables>;
export const CreatePageDocument = gql`
    mutation CreatePage($site: ID!, $input: CreatePageInput!) {
  createPage(site: $site, input: $input) {
    page {
      id
      ...PageFields
    }
  }
}
    ${PageFieldsFragmentDoc}`;
export type CreatePageMutationFn = ApolloReactCommon.MutationFunction<CreatePageMutation, CreatePageMutationVariables>;

/**
 * __useCreatePageMutation__
 *
 * To run a mutation, you first call `useCreatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPageMutation, { data, loading, error }] = useCreatePageMutation({
 *   variables: {
 *      site: // value for 'site'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePageMutation, CreatePageMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePageMutation, CreatePageMutationVariables>(CreatePageDocument, baseOptions);
      }
export type CreatePageMutationHookResult = ReturnType<typeof useCreatePageMutation>;
export type CreatePageMutationResult = ApolloReactCommon.MutationResult<CreatePageMutation>;
export type CreatePageMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePageMutation, CreatePageMutationVariables>;
export const UpdatePageDocument = gql`
    mutation UpdatePage($site: ID!, $page: ID!, $input: UpdatePageInput!) {
  updatePage(site: $site, page: $page, input: $input) {
    page {
      id
      ...PageFields
    }
  }
}
    ${PageFieldsFragmentDoc}`;
export type UpdatePageMutationFn = ApolloReactCommon.MutationFunction<UpdatePageMutation, UpdatePageMutationVariables>;

/**
 * __useUpdatePageMutation__
 *
 * To run a mutation, you first call `useUpdatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageMutation, { data, loading, error }] = useUpdatePageMutation({
 *   variables: {
 *      site: // value for 'site'
 *      page: // value for 'page'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePageMutation, UpdatePageMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePageMutation, UpdatePageMutationVariables>(UpdatePageDocument, baseOptions);
      }
export type UpdatePageMutationHookResult = ReturnType<typeof useUpdatePageMutation>;
export type UpdatePageMutationResult = ApolloReactCommon.MutationResult<UpdatePageMutation>;
export type UpdatePageMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePageMutation, UpdatePageMutationVariables>;
export const GetPageListDocument = gql`
    query GetPageList($site: ID!) {
  pages(site: $site) {
    id
    siteId
    name
    url
    enabled
    isTemplate
    updatedAt
  }
}
    `;

/**
 * __useGetPageListQuery__
 *
 * To run a query within a React component, call `useGetPageListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageListQuery({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useGetPageListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPageListQuery, GetPageListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPageListQuery, GetPageListQueryVariables>(GetPageListDocument, baseOptions);
      }
export function useGetPageListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPageListQuery, GetPageListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPageListQuery, GetPageListQueryVariables>(GetPageListDocument, baseOptions);
        }
export type GetPageListQueryHookResult = ReturnType<typeof useGetPageListQuery>;
export type GetPageListLazyQueryHookResult = ReturnType<typeof useGetPageListLazyQuery>;
export type GetPageListQueryResult = ApolloReactCommon.QueryResult<GetPageListQuery, GetPageListQueryVariables>;
export const EnablePageDocument = gql`
    mutation EnablePage($site: ID!, $page: ID!) {
  enablePage(site: $site, page: $page) {
    id
    enabled
  }
}
    `;
export type EnablePageMutationFn = ApolloReactCommon.MutationFunction<EnablePageMutation, EnablePageMutationVariables>;

/**
 * __useEnablePageMutation__
 *
 * To run a mutation, you first call `useEnablePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnablePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enablePageMutation, { data, loading, error }] = useEnablePageMutation({
 *   variables: {
 *      site: // value for 'site'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useEnablePageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EnablePageMutation, EnablePageMutationVariables>) {
        return ApolloReactHooks.useMutation<EnablePageMutation, EnablePageMutationVariables>(EnablePageDocument, baseOptions);
      }
export type EnablePageMutationHookResult = ReturnType<typeof useEnablePageMutation>;
export type EnablePageMutationResult = ApolloReactCommon.MutationResult<EnablePageMutation>;
export type EnablePageMutationOptions = ApolloReactCommon.BaseMutationOptions<EnablePageMutation, EnablePageMutationVariables>;
export const DisablePageDocument = gql`
    mutation DisablePage($site: ID!, $page: ID!) {
  disablePage(site: $site, page: $page) {
    id
    enabled
  }
}
    `;
export type DisablePageMutationFn = ApolloReactCommon.MutationFunction<DisablePageMutation, DisablePageMutationVariables>;

/**
 * __useDisablePageMutation__
 *
 * To run a mutation, you first call `useDisablePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisablePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disablePageMutation, { data, loading, error }] = useDisablePageMutation({
 *   variables: {
 *      site: // value for 'site'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useDisablePageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisablePageMutation, DisablePageMutationVariables>) {
        return ApolloReactHooks.useMutation<DisablePageMutation, DisablePageMutationVariables>(DisablePageDocument, baseOptions);
      }
export type DisablePageMutationHookResult = ReturnType<typeof useDisablePageMutation>;
export type DisablePageMutationResult = ApolloReactCommon.MutationResult<DisablePageMutation>;
export type DisablePageMutationOptions = ApolloReactCommon.BaseMutationOptions<DisablePageMutation, DisablePageMutationVariables>;
export const RemovePageDocument = gql`
    mutation RemovePage($site: ID!, $page: ID!) {
  removePage(site: $site, page: $page) {
    site
    page
  }
}
    `;
export type RemovePageMutationFn = ApolloReactCommon.MutationFunction<RemovePageMutation, RemovePageMutationVariables>;

/**
 * __useRemovePageMutation__
 *
 * To run a mutation, you first call `useRemovePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePageMutation, { data, loading, error }] = useRemovePageMutation({
 *   variables: {
 *      site: // value for 'site'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useRemovePageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemovePageMutation, RemovePageMutationVariables>) {
        return ApolloReactHooks.useMutation<RemovePageMutation, RemovePageMutationVariables>(RemovePageDocument, baseOptions);
      }
export type RemovePageMutationHookResult = ReturnType<typeof useRemovePageMutation>;
export type RemovePageMutationResult = ApolloReactCommon.MutationResult<RemovePageMutation>;
export type RemovePageMutationOptions = ApolloReactCommon.BaseMutationOptions<RemovePageMutation, RemovePageMutationVariables>;
export const GetRedirectDocument = gql`
    query GetRedirect($site: ID!, $redirect: ID!) {
  redirect(site: $site, redirect: $redirect) {
    ...redirect
  }
}
    ${RedirectFragmentDoc}`;

/**
 * __useGetRedirectQuery__
 *
 * To run a query within a React component, call `useGetRedirectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRedirectQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRedirectQuery({
 *   variables: {
 *      site: // value for 'site'
 *      redirect: // value for 'redirect'
 *   },
 * });
 */
export function useGetRedirectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRedirectQuery, GetRedirectQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRedirectQuery, GetRedirectQueryVariables>(GetRedirectDocument, baseOptions);
      }
export function useGetRedirectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRedirectQuery, GetRedirectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRedirectQuery, GetRedirectQueryVariables>(GetRedirectDocument, baseOptions);
        }
export type GetRedirectQueryHookResult = ReturnType<typeof useGetRedirectQuery>;
export type GetRedirectLazyQueryHookResult = ReturnType<typeof useGetRedirectLazyQuery>;
export type GetRedirectQueryResult = ApolloReactCommon.QueryResult<GetRedirectQuery, GetRedirectQueryVariables>;
export const SaveRedirectDocument = gql`
    mutation SaveRedirect($site: ID!, $redirect: ID!, $source: String!, $dest: String!, $type: RedirectType!) {
  saveRedirect(site: $site, redirect: $redirect, source: $source, dest: $dest, type: $type) {
    redirect {
      ...redirect
    }
  }
}
    ${RedirectFragmentDoc}`;
export type SaveRedirectMutationFn = ApolloReactCommon.MutationFunction<SaveRedirectMutation, SaveRedirectMutationVariables>;

/**
 * __useSaveRedirectMutation__
 *
 * To run a mutation, you first call `useSaveRedirectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveRedirectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveRedirectMutation, { data, loading, error }] = useSaveRedirectMutation({
 *   variables: {
 *      site: // value for 'site'
 *      redirect: // value for 'redirect'
 *      source: // value for 'source'
 *      dest: // value for 'dest'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSaveRedirectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveRedirectMutation, SaveRedirectMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveRedirectMutation, SaveRedirectMutationVariables>(SaveRedirectDocument, baseOptions);
      }
export type SaveRedirectMutationHookResult = ReturnType<typeof useSaveRedirectMutation>;
export type SaveRedirectMutationResult = ApolloReactCommon.MutationResult<SaveRedirectMutation>;
export type SaveRedirectMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveRedirectMutation, SaveRedirectMutationVariables>;
export const CreateRedirectDocument = gql`
    mutation CreateRedirect($site: ID!, $source: String!, $dest: String!, $type: RedirectType!) {
  createRedirect(site: $site, source: $source, dest: $dest, type: $type) {
    redirect {
      ...redirect
    }
  }
}
    ${RedirectFragmentDoc}`;
export type CreateRedirectMutationFn = ApolloReactCommon.MutationFunction<CreateRedirectMutation, CreateRedirectMutationVariables>;

/**
 * __useCreateRedirectMutation__
 *
 * To run a mutation, you first call `useCreateRedirectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRedirectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRedirectMutation, { data, loading, error }] = useCreateRedirectMutation({
 *   variables: {
 *      site: // value for 'site'
 *      source: // value for 'source'
 *      dest: // value for 'dest'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateRedirectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRedirectMutation, CreateRedirectMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRedirectMutation, CreateRedirectMutationVariables>(CreateRedirectDocument, baseOptions);
      }
export type CreateRedirectMutationHookResult = ReturnType<typeof useCreateRedirectMutation>;
export type CreateRedirectMutationResult = ApolloReactCommon.MutationResult<CreateRedirectMutation>;
export type CreateRedirectMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRedirectMutation, CreateRedirectMutationVariables>;
export const GetRedirectsDocument = gql`
    query GetRedirects($site: ID!) {
  redirects(site: $site) {
    id
    source
    dest
    redirectType
  }
}
    `;

/**
 * __useGetRedirectsQuery__
 *
 * To run a query within a React component, call `useGetRedirectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRedirectsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRedirectsQuery({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useGetRedirectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRedirectsQuery, GetRedirectsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRedirectsQuery, GetRedirectsQueryVariables>(GetRedirectsDocument, baseOptions);
      }
export function useGetRedirectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRedirectsQuery, GetRedirectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRedirectsQuery, GetRedirectsQueryVariables>(GetRedirectsDocument, baseOptions);
        }
export type GetRedirectsQueryHookResult = ReturnType<typeof useGetRedirectsQuery>;
export type GetRedirectsLazyQueryHookResult = ReturnType<typeof useGetRedirectsLazyQuery>;
export type GetRedirectsQueryResult = ApolloReactCommon.QueryResult<GetRedirectsQuery, GetRedirectsQueryVariables>;
export const DeleteRedirectDocument = gql`
    mutation DeleteRedirect($site: ID!, $redirect: ID!) {
  deleteRedirect(site: $site, redirect: $redirect) {
    site
    redirect
  }
}
    `;
export type DeleteRedirectMutationFn = ApolloReactCommon.MutationFunction<DeleteRedirectMutation, DeleteRedirectMutationVariables>;

/**
 * __useDeleteRedirectMutation__
 *
 * To run a mutation, you first call `useDeleteRedirectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRedirectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRedirectMutation, { data, loading, error }] = useDeleteRedirectMutation({
 *   variables: {
 *      site: // value for 'site'
 *      redirect: // value for 'redirect'
 *   },
 * });
 */
export function useDeleteRedirectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRedirectMutation, DeleteRedirectMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRedirectMutation, DeleteRedirectMutationVariables>(DeleteRedirectDocument, baseOptions);
      }
export type DeleteRedirectMutationHookResult = ReturnType<typeof useDeleteRedirectMutation>;
export type DeleteRedirectMutationResult = ApolloReactCommon.MutationResult<DeleteRedirectMutation>;
export type DeleteRedirectMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRedirectMutation, DeleteRedirectMutationVariables>;
export const GetSiteDocument = gql`
    query GetSite($site: ID!) {
  site(id: $site) {
    ...site
  }
}
    ${SiteFragmentDoc}`;

/**
 * __useGetSiteQuery__
 *
 * To run a query within a React component, call `useGetSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteQuery({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useGetSiteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteQuery, GetSiteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteQuery, GetSiteQueryVariables>(GetSiteDocument, baseOptions);
      }
export function useGetSiteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteQuery, GetSiteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteQuery, GetSiteQueryVariables>(GetSiteDocument, baseOptions);
        }
export type GetSiteQueryHookResult = ReturnType<typeof useGetSiteQuery>;
export type GetSiteLazyQueryHookResult = ReturnType<typeof useGetSiteLazyQuery>;
export type GetSiteQueryResult = ApolloReactCommon.QueryResult<GetSiteQuery, GetSiteQueryVariables>;
export const CreateSiteDocument = gql`
    mutation CreateSite($input: CreateSiteInput!) {
  createSite(input: $input) {
    ...site
  }
}
    ${SiteFragmentDoc}`;
export type CreateSiteMutationFn = ApolloReactCommon.MutationFunction<CreateSiteMutation, CreateSiteMutationVariables>;

/**
 * __useCreateSiteMutation__
 *
 * To run a mutation, you first call `useCreateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteMutation, { data, loading, error }] = useCreateSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSiteMutation, CreateSiteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSiteMutation, CreateSiteMutationVariables>(CreateSiteDocument, baseOptions);
      }
export type CreateSiteMutationHookResult = ReturnType<typeof useCreateSiteMutation>;
export type CreateSiteMutationResult = ApolloReactCommon.MutationResult<CreateSiteMutation>;
export type CreateSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSiteMutation, CreateSiteMutationVariables>;
export const UpdateSiteDocument = gql`
    mutation UpdateSite($site: ID!, $input: UpdateSiteInput!) {
  updateSite(id: $site, input: $input) {
    ...site
  }
}
    ${SiteFragmentDoc}`;
export type UpdateSiteMutationFn = ApolloReactCommon.MutationFunction<UpdateSiteMutation, UpdateSiteMutationVariables>;

/**
 * __useUpdateSiteMutation__
 *
 * To run a mutation, you first call `useUpdateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteMutation, { data, loading, error }] = useUpdateSiteMutation({
 *   variables: {
 *      site: // value for 'site'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSiteMutation, UpdateSiteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSiteMutation, UpdateSiteMutationVariables>(UpdateSiteDocument, baseOptions);
      }
export type UpdateSiteMutationHookResult = ReturnType<typeof useUpdateSiteMutation>;
export type UpdateSiteMutationResult = ApolloReactCommon.MutationResult<UpdateSiteMutation>;
export type UpdateSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSiteMutation, UpdateSiteMutationVariables>;
export const GetSiteSettingsDocument = gql`
    query GetSiteSettings($site: ID!) {
  site(id: $site) {
    id
    name
    settings
  }
}
    `;

/**
 * __useGetSiteSettingsQuery__
 *
 * To run a query within a React component, call `useGetSiteSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteSettingsQuery({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useGetSiteSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteSettingsQuery, GetSiteSettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteSettingsQuery, GetSiteSettingsQueryVariables>(GetSiteSettingsDocument, baseOptions);
      }
export function useGetSiteSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteSettingsQuery, GetSiteSettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteSettingsQuery, GetSiteSettingsQueryVariables>(GetSiteSettingsDocument, baseOptions);
        }
export type GetSiteSettingsQueryHookResult = ReturnType<typeof useGetSiteSettingsQuery>;
export type GetSiteSettingsLazyQueryHookResult = ReturnType<typeof useGetSiteSettingsLazyQuery>;
export type GetSiteSettingsQueryResult = ApolloReactCommon.QueryResult<GetSiteSettingsQuery, GetSiteSettingsQueryVariables>;
export const SaveSiteSettingsDocument = gql`
    mutation SaveSiteSettings($site: ID!, $input: SiteSettingsInput!) {
  saveSiteSettings(site: $site, input: $input) {
    id
    name
  }
}
    `;
export type SaveSiteSettingsMutationFn = ApolloReactCommon.MutationFunction<SaveSiteSettingsMutation, SaveSiteSettingsMutationVariables>;

/**
 * __useSaveSiteSettingsMutation__
 *
 * To run a mutation, you first call `useSaveSiteSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSiteSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSiteSettingsMutation, { data, loading, error }] = useSaveSiteSettingsMutation({
 *   variables: {
 *      site: // value for 'site'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveSiteSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveSiteSettingsMutation, SaveSiteSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveSiteSettingsMutation, SaveSiteSettingsMutationVariables>(SaveSiteSettingsDocument, baseOptions);
      }
export type SaveSiteSettingsMutationHookResult = ReturnType<typeof useSaveSiteSettingsMutation>;
export type SaveSiteSettingsMutationResult = ApolloReactCommon.MutationResult<SaveSiteSettingsMutation>;
export type SaveSiteSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveSiteSettingsMutation, SaveSiteSettingsMutationVariables>;
export const GetSitesDocument = gql`
    query GetSites {
  sites {
    id
    code
    domain
    name
  }
}
    `;

/**
 * __useGetSitesQuery__
 *
 * To run a query within a React component, call `useGetSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSitesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSitesQuery, GetSitesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSitesQuery, GetSitesQueryVariables>(GetSitesDocument, baseOptions);
      }
export function useGetSitesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSitesQuery, GetSitesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSitesQuery, GetSitesQueryVariables>(GetSitesDocument, baseOptions);
        }
export type GetSitesQueryHookResult = ReturnType<typeof useGetSitesQuery>;
export type GetSitesLazyQueryHookResult = ReturnType<typeof useGetSitesLazyQuery>;
export type GetSitesQueryResult = ApolloReactCommon.QueryResult<GetSitesQuery, GetSitesQueryVariables>;
export const GetThemeFileTreeDocument = gql`
    query GetThemeFileTree($site: ID!) {
  themeFileTree(site: $site) {
    id
    siteId
    files
  }
}
    `;

/**
 * __useGetThemeFileTreeQuery__
 *
 * To run a query within a React component, call `useGetThemeFileTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemeFileTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemeFileTreeQuery({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useGetThemeFileTreeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetThemeFileTreeQuery, GetThemeFileTreeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetThemeFileTreeQuery, GetThemeFileTreeQueryVariables>(GetThemeFileTreeDocument, baseOptions);
      }
export function useGetThemeFileTreeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetThemeFileTreeQuery, GetThemeFileTreeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetThemeFileTreeQuery, GetThemeFileTreeQueryVariables>(GetThemeFileTreeDocument, baseOptions);
        }
export type GetThemeFileTreeQueryHookResult = ReturnType<typeof useGetThemeFileTreeQuery>;
export type GetThemeFileTreeLazyQueryHookResult = ReturnType<typeof useGetThemeFileTreeLazyQuery>;
export type GetThemeFileTreeQueryResult = ApolloReactCommon.QueryResult<GetThemeFileTreeQuery, GetThemeFileTreeQueryVariables>;
export const SaveThemeFileDocument = gql`
    mutation SaveThemeFile($site: ID!, $path: String!, $content: String!) {
  saveThemeFile(site: $site, path: $path, content: $content) {
    path
  }
}
    `;
export type SaveThemeFileMutationFn = ApolloReactCommon.MutationFunction<SaveThemeFileMutation, SaveThemeFileMutationVariables>;

/**
 * __useSaveThemeFileMutation__
 *
 * To run a mutation, you first call `useSaveThemeFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveThemeFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveThemeFileMutation, { data, loading, error }] = useSaveThemeFileMutation({
 *   variables: {
 *      site: // value for 'site'
 *      path: // value for 'path'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useSaveThemeFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveThemeFileMutation, SaveThemeFileMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveThemeFileMutation, SaveThemeFileMutationVariables>(SaveThemeFileDocument, baseOptions);
      }
export type SaveThemeFileMutationHookResult = ReturnType<typeof useSaveThemeFileMutation>;
export type SaveThemeFileMutationResult = ApolloReactCommon.MutationResult<SaveThemeFileMutation>;
export type SaveThemeFileMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveThemeFileMutation, SaveThemeFileMutationVariables>;
export const GetUserAndSitesDocument = gql`
    query GetUserAndSites($id: ID!, $isNew: Boolean = false) {
  user: user(id: $id) @skip(if: $isNew) {
    ...userSiteFields
  }
  sites: sites {
    id
    name
  }
}
    ${UserSiteFieldsFragmentDoc}`;

/**
 * __useGetUserAndSitesQuery__
 *
 * To run a query within a React component, call `useGetUserAndSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAndSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAndSitesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      isNew: // value for 'isNew'
 *   },
 * });
 */
export function useGetUserAndSitesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserAndSitesQuery, GetUserAndSitesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserAndSitesQuery, GetUserAndSitesQueryVariables>(GetUserAndSitesDocument, baseOptions);
      }
export function useGetUserAndSitesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserAndSitesQuery, GetUserAndSitesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserAndSitesQuery, GetUserAndSitesQueryVariables>(GetUserAndSitesDocument, baseOptions);
        }
export type GetUserAndSitesQueryHookResult = ReturnType<typeof useGetUserAndSitesQuery>;
export type GetUserAndSitesLazyQueryHookResult = ReturnType<typeof useGetUserAndSitesLazyQuery>;
export type GetUserAndSitesQueryResult = ApolloReactCommon.QueryResult<GetUserAndSitesQuery, GetUserAndSitesQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($user: CreateUserInput!) {
  createUser(user: $user) {
    user {
      ...userSiteFields
    }
  }
}
    ${UserSiteFieldsFragmentDoc}`;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $user: UpdateUserInput!) {
  updateUser(id: $id, user: $user) {
    user {
      ...userSiteFields
    }
  }
}
    ${UserSiteFieldsFragmentDoc}`;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetUserListDocument = gql`
    query GetUserList {
  users {
    id
    username
    isSuperUser
  }
}
    `;

/**
 * __useGetUserListQuery__
 *
 * To run a query within a React component, call `useGetUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserListQuery, GetUserListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserListQuery, GetUserListQueryVariables>(GetUserListDocument, baseOptions);
      }
export function useGetUserListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserListQuery, GetUserListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserListQuery, GetUserListQueryVariables>(GetUserListDocument, baseOptions);
        }
export type GetUserListQueryHookResult = ReturnType<typeof useGetUserListQuery>;
export type GetUserListLazyQueryHookResult = ReturnType<typeof useGetUserListLazyQuery>;
export type GetUserListQueryResult = ApolloReactCommon.QueryResult<GetUserListQuery, GetUserListQueryVariables>;
export const UploadFileDocument = gql`
    mutation UploadFile($file: Upload!) {
  singleUpload(file: $file) {
    filename
    mimetype
    encoding
  }
}
    `;
export type UploadFileMutationFn = ApolloReactCommon.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, baseOptions);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = ApolloReactCommon.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;