import { ApolloClient } from 'apollo-client'
// import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
// import { store } from '.'

// const httpLink = createHttpLink({
//   uri: '/api/graphql',
// })

const httpLink = createUploadLink({
  uri: '/api/graphql',
})

const authLink = setContext((_, { headers, other }) => {
  // const state = store.getState()
  // const token = state.auth.token
  const token = null
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
})

export default client
