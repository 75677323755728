import * as React from 'react'
import { Link } from '@reach/router'
import { ToastContainer } from 'react-toastify'

export default class ManagementLayout extends React.Component<any> {
  render() {
    return (
      <div className="layout">
        <div className="primary management">
          <header className="ui basic inverted segment">
            <nav className="ui secondary inverted menu">
              <div className="item">
                <h1>Gouda Management</h1>
              </div>
              <Link to="/management/sites" className="item">
                Manage Sites
              </Link>
              <Link to="/dashboard" className="item">
                Back to Site Chooser
              </Link>
            </nav>
          </header>
          <main>{this.props.children}</main>
        </div>
        <ToastContainer newestOnTop />
      </div>
    )
  }
}
