import React from 'react'
import { useCurrentSite } from './Sites'

// lifted from reach router Link props definition
type Omit<T, K> = Pick<T, Exclude<keyof T, K>>
type AnchorProps = Omit<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >,
  'href' // remove href, as it's ignored by the router
>

interface Props extends AnchorProps {
  to: string
  children: React.ReactNode
}

export default function SiteLink({ to = '', children, ...rest }: Props) {
  const site = useCurrentSite()
  const port = site.domain.endsWith('.cms') ? ':8000' : ''
  const domain = `${site.domain}${port}`

  // strip leading slash if present
  if (to.charAt(0) === '/') to = to.substr(1)

  return (
    <a href={`http://${domain}/${to}`} {...rest}>
      {children}
    </a>
  )
}
