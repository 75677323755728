import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import { globalHistory } from '@reach/router/lib/history'
import ErrorBoundry from './components/ErrorBoundry'
import Routes from './routes'
import apolloClient from './apollo-client'
import { post, endpoint } from './actions/_util'
import LoadingIndicator from './components/LoadingIndicator'
import { AuthProvider } from './AuthContext'

initAnalytics()
globalHistory.listen(() => {
  if ('gtag' in window) {
    // @ts-ignore
    window.gtag('config', 'UA-118899224-2')
  }
})

post(endpoint('/verify-login'), {})
  .then((r) => Promise.all([r, r.json()]))
  .then(([r, json]) => {
    console.log('verifyLogin:', { r, json })
    // store.dispatch(updateAuthDetails(json))
    const { authenticated, user } = json
    return { authenticated, ...user }
  })
  .then((initialUser) => {
    ReactDOM.render(
      <ErrorBoundry>
        <AuthProvider user={initialUser}>
          <ApolloHooksProvider client={apolloClient}>
            <ApolloProvider client={apolloClient}>
              <React.Suspense fallback={<LoadingIndicator />}>
                <Routes />
              </React.Suspense>
            </ApolloProvider>
          </ApolloHooksProvider>
        </AuthProvider>
      </ErrorBoundry>,
      document.getElementById('root')
    )
  })

declare global {
  interface Window {
    dataLayer: Array<any>
    gtag: (key: string, value: any) => unknown
  }
}

function initAnalytics() {
  const s = document.createElement('script')
  s.async = true
  s.src = 'https://www.googletagmanager.com/gtag/js?id=UA-118899224-2'
  document.body.appendChild(s)

  /* eslint-disable */
  window.dataLayer = window.dataLayer || []

  function gtag(key: string, value: any) {
    window.dataLayer.push(arguments)
  }

  window.gtag = gtag

  gtag('js', new Date())
  gtag('config', 'UA-118899224-2')
  /* eslint-enable */
}
