import * as React from 'react'
import { Router, Redirect, RouteComponentProps } from '@reach/router'
import LoginPage from './pages/LoginPage'
import SitesWrapper from './components/Layout'
import ManagementLayout from './components/ManagementLayout'
import SiteChooser from './components/SiteChooser'
import * as routes from './routes.loadable'
import ensureAuth from './ensureAuth'
import Playground from './pages/Playground'

const SiteChooserWithAuth = ensureAuth(SiteChooser)
const SitesWrapperWithAuth = ensureAuth(SitesWrapper)

export default class TestRouter extends React.Component {
  render() {
    return (
      <Router primary={false}>
        <Redirect from="/" to="dashboard" noThrow />
        <SiteChooserWithAuth path="dashboard" />

        <LoginPage path="login" />

        <SitesWrapperWithAuth path="sites/:site">
          <routes.Dashboard path="dashboard" />
          <routes.SettingsPage path="settings" />

          <routes.PageList path="pages" />
          <routes.PageEditor path="pages/:id" />

          <routes.BlogPostList path="blog-posts" />
          <routes.BlogPostEditor path="blog-posts/:id" />
          <routes.BlogAuthorList path="blog-authors" />
          <routes.BlogAuthorEditor path="blog-authors/:id" />

          <routes.EventList path="events" />
          <routes.EventEditor path="events/:id" />

          <routes.RedirectList path="redirects" />
          <routes.RedirectList path="redirects/*" />

          <routes.FormList path="forms" />
          <routes.FormEditor path="forms/:id" />
          <routes.FormSubmissionList path="forms/:form/submissions" />
          <routes.FormSubmissionDetails path="forms/:form/submissions/:submission" />

          <routes.MenuList path="menus" />
          <routes.MenuEditor path="menus/:id" />

          <routes.FoodMenuList path="food-menus" />
          <routes.FoodMenuEditor path="food-menus/:id" />
          <routes.FoodMenuEditor2 path="food-menus2/:id" />

          <SiteRedirect path="commerce" to="commerce/orders" />
          <routes.CommerceMain path="commerce/*" />

          <routes.TemplateEditor path="templates" />

          <routes.UserList path="users" />
          <routes.UserEditor path="users/:id" />

          <routes.InternalSiteRoutesList path="routes" />
          <routes.InternalSiteAssetsPage path="assets" />

          <Playground path="playground" />
        </SitesWrapperWithAuth>

        <ManagementLayout path="management">
          <routes.SiteList path="sites" />
          <routes.SiteEditor path="sites/:id" />
        </ManagementLayout>
      </Router>
    )
  }
}

function SiteRedirect({
  to,
  site,
}: RouteComponentProps<{ to: string; site: string }>) {
  return <Redirect to={`/sites/${site}/${to}`} noThrow />
}
