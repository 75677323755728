import * as React from 'react'
import { Redirect, WindowLocation } from '@reach/router'
import { useAuthInfo } from './AuthContext'

type EProps = { location?: WindowLocation }

export default function ensureAuth<P>(Comp: React.ComponentType<P>) {
  return function EnsureAuthWrapper(props: P & EProps) {
    const { user } = useAuthInfo()

    if (!user.authenticated) {
      const url = props.location
        ? `/login?redirect=${encodeURIComponent(props.location.pathname)}`
        : '/login'
      return <Redirect to={url} noThrow />
    }

    return <Comp {...props} />
  }
}
