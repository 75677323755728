import styled, { css } from 'styled-components'
import { Link } from '@reach/router'

type SiteSwitcherProps = {
  isOpen: boolean
}

export const SiteSwitcher = styled.div<SiteSwitcherProps>`
  ${(props) =>
    !props.isOpen &&
    css`
      box-shadow: 0px 1px 6px black;
    `}
`

export const SiteSwitcherContent = styled.div`
  padding: 24px;
  text-align: center;

  &:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.15);
  }
`

export const SitesList = styled.div`
  padding: 24px;
`

export const SiteLink = styled(Link)`
  overflow: hidden;
  text-overflow: ellipsis;
`
