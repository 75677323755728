import * as React from 'react'
import styled from 'styled-components'

type Props = {
  children?: JSX.Element
}

type State = {
  error: Error | null
  errorInfo: React.ErrorInfo | null
}

const Wrapper = styled.div`
  padding: 30px;
  background: #cc0000;
  color: #fff;
  height: 100vh;
`

const Details = styled.details`
  white-space: pre-wrap;
`

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.log('Caught an error', errorInfo)
    console.error(error)
    this.setState({
      error,
      errorInfo,
    })
  }

  render() {
    if (process.env.NODE_ENV === 'development' || !this.state.errorInfo) {
      return this.props.children
    }

    return (
      <Wrapper>
        <h2>Something went wrong.</h2>
        <Details open>
          {this.state.error && this.state.error.toString()}
          <br />
          {this.state.errorInfo.componentStack}
        </Details>
      </Wrapper>
    )
  }
}
