import { schema } from 'normalizr'
import { mapKeys, camelCase, kebabCase } from 'lodash'

// const endpoint = (url) => `http://localhost:8000/api${url}`
export const endpoint = (url: string) => `/api${url}`

export const formatPayload = (payload: Object) =>
  mapKeys(payload, (_, key) => kebabCase(key))

export const createSchema = (name: string, definition: Object = {}) =>
  new schema.Entity(name, definition as any, {
    processStrategy(value) {
      return mapKeys(value, (_, key) => camelCase(key))
    },
  })

export function get(url: string, accessToken?: string) {
  return fetch(url, {
    method: 'GET',
    headers: getHeaders(accessToken),
    credentials: 'same-origin',
  } as object)
}

export function post(url: string, body?: object, accessToken?: string) {
  return fetch(url, {
    method: 'POST',
    headers: getHeaders(accessToken),
    body: body ? JSON.stringify(formatPayload(body)) : null,
    credentials: 'same-origin',
  } as object)
}

export function put(url: string, body: Object, accessToken?: string) {
  return fetch(url, {
    method: 'PUT',
    headers: getHeaders(accessToken),
    body: JSON.stringify(formatPayload(body)),
    credentials: 'same-origin',
  } as object)
}

export function del(url: string, accessToken?: string) {
  return fetch(url, {
    method: 'DELETE',
    headers: getHeaders(accessToken),
    credentials: 'same-origin',
  } as object)
}

function getHeaders(accessToken?: string) {
  const headers: object = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  if (accessToken) {
    ;(headers as any).Authorization = `Bearer ${accessToken}`
  }

  return headers
}
