import React from 'react'
import { Loader } from 'semantic-ui-react'

export default function LoadingIndicator() {
  return (
    <div>
      <Loader active inline />
      <span style={{ marginLeft: 10 }}>Loading&hellip;</span>
    </div>
  )
}
