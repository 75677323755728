import React, { useState, useMemo } from 'react'
import { Link, RouteComponentProps } from '@reach/router'
import Helmet from 'react-helmet'
import cx from 'classnames'
import { Icon } from 'semantic-ui-react'
import { ToastContainer } from 'react-toastify'
import SiteLink from '../SiteLink'
import LoadingIndicator from '../LoadingIndicator'
import { AuthContext, useAuthInfo } from '../../AuthContext'
import SiteSwitcher from './SiteSwitcher'
import { useSiteList, CurrentSiteProvider } from '../Sites'

import '../../styles/app.css'

const isDevelopment = process.env.NODE_ENV === 'development'

const NavLink = (props: any) => (
  <Link
    {...props}
    getProps={({ isPartiallyCurrent }) => ({
      className: cx(props.className, { active: isPartiallyCurrent }),
    })}
  />
)

const isDashboard = (location: any) =>
  location.pathname === '/dashboard' ||
  location.pathname.match(/\/sites\/\d+\/dashboard/)

function Username() {
  const { user } = React.useContext(AuthContext)
  console.log('Username:', user)
  if (!user.authenticated) {
    return null
  }

  return (
    <span>
      <Icon name="user" /> {user.username}
    </span>
  )
}

interface Props extends RouteComponentProps<{ site: string }> {
  children?: React.ReactNode
}

export default function Layout(props: Props) {
  const [forceVisible, setForceVisible] = useState(false)
  const [hideNav, setHideNav] = useState(false)
  const { data, loading } = useSiteList()
  const { user, logout } = useAuthInfo()
  const isSuperUser = user.authenticated && user.isSuperUser
  const handleLogout = () => {
    setTimeout(logout, 300)
  }

  const siteId = props.site!
  const currentSite = useMemo(() => {
    if (props.site && data && data.sites) {
      return data.sites.find((s) => s.id === siteId) || false
    }

    return false
  }, [data, siteId, props.site])

  if (loading) {
    return <LoadingIndicator />
  }

  if (!data || !data.sites || !currentSite) {
    throw new Error('Unable to load site data')
  }

  const sidebarStyle = forceVisible
    ? { display: 'block', maxWidth: '100%' }
    : {}

  const LayoutNavLink = (props: any) => (
    <NavLink
      {...props}
      onClick={() => {
        setForceVisible(false)
      }}
    />
  )

  return (
    <CurrentSiteProvider site={currentSite}>
      <div className="layout">
        <Helmet
          defaultTitle={currentSite.name}
          titleTemplate={`%s - ${currentSite.name} - GoudaCMS`}
        />
        <div className="sidebar" style={sidebarStyle}>
          <SiteSwitcher
            currentSite={currentSite}
            sites={data.sites}
            onVisibilityChange={(visible) => {
              setHideNav(visible)
            }}
            showCloseButton={forceVisible}
            onClose={() => void setForceVisible(false)}
          />
          <div
            style={{
              padding: '24px 32px',
              display: hideNav ? 'none' : 'block',
            }}
          >
            <div className="ui fluid secondary vertical inverted menu">
              <LayoutNavLink to={`/sites/${siteId}/dashboard`} className="item">
                <Icon name="dashboard" className="left" /> Dashboard
              </LayoutNavLink>

              <LayoutNavLink to={`/sites/${siteId}/pages`} className="item">
                <Icon name="file outline" className="left" /> Pages
              </LayoutNavLink>

              <LayoutNavLink
                to={`/sites/${siteId}/blog-posts`}
                className="item"
              >
                <Icon name="newspaper outline" className="left" /> Blog Posts
              </LayoutNavLink>

              {isDevelopment && (
                <LayoutNavLink to={`/sites/${siteId}/events`} className="item">
                  <Icon name="calendar" className="left" /> Events
                </LayoutNavLink>
              )}

              <LayoutNavLink to={`/sites/${siteId}/commerce`} className="item">
                <Icon name="money" className="left" /> Commerce
              </LayoutNavLink>
              <LayoutNavLink to={`/sites/${siteId}/forms`} className="item">
                <Icon name="comments" className="left" /> Forms
              </LayoutNavLink>
              <LayoutNavLink to={`/sites/${siteId}/menus`} className="item">
                <Icon name="bars" className="left" /> Menus
              </LayoutNavLink>
              <LayoutNavLink
                to={`/sites/${siteId}/food-menus`}
                className="item"
              >
                <Icon name="food" className="left" /> Food Menus
              </LayoutNavLink>

              <LayoutNavLink to={`/sites/${siteId}/redirects`} className="item">
                <Icon name="arrow right" className="left" /> Redirects
              </LayoutNavLink>

              <LayoutNavLink to={`/sites/${siteId}/templates`} className="item">
                <Icon name="file code outline" className="left" /> Templates
              </LayoutNavLink>

              <LayoutNavLink to={`/sites/${siteId}/settings`} className="item">
                <Icon name="settings" className="left" /> Site Settings
              </LayoutNavLink>

              {isSuperUser && (
                <React.Fragment>
                  <div className="ui inverted header">Admin</div>

                  <LayoutNavLink
                    to={`/sites/${siteId}/assets`}
                    className="item"
                  >
                    <Icon name="photo" className="left" /> Assets
                  </LayoutNavLink>

                  <LayoutNavLink
                    to={`/sites/${siteId}/routes`}
                    className="item"
                  >
                    <Icon name="train" className="left" /> Site Routes
                  </LayoutNavLink>

                  <LayoutNavLink to={`/sites/${siteId}/users`} className="item">
                    <Icon name="user" className="left" /> Users
                  </LayoutNavLink>

                  <LayoutNavLink to={`/management/sites`} className="item">
                    <Icon name="sitemap" className="left" /> Sites
                  </LayoutNavLink>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <div
          className={cx('primary', {
            dashboard: isDashboard(props.location),
          })}
          style={forceVisible ? { display: 'none' } : {}}
        >
          <header className="ui basic inverted violet segment">
            <nav className="ui secondary inverted menu">
              <a
                className="icon item"
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  setForceVisible(!forceVisible)
                }}
              >
                <Icon name="bars" />
              </a>
              <div className="item">
                <h1>{currentSite.name}</h1>
              </div>
            </nav>
          </header>
          <div className="primary-header">
            {isDevelopment ? (
              <div style={{ color: '#cc0000', fontWeight: 'bold' }}>
                Development Mode
              </div>
            ) : (
              <div />
            )}
            <div>
              <div className="user-ident">
                <SiteLink to="" target="_blank">
                  View Site
                </SiteLink>
                <Username />
                <Link to="/login" onClick={handleLogout}>
                  logout
                </Link>
              </div>
            </div>
          </div>

          <main>
            <React.Suspense fallback={<LoadingIndicator />}>
              {props.children}
            </React.Suspense>
          </main>
        </div>
        <ToastContainer position="bottom-right" />
      </div>
    </CurrentSiteProvider>
  )
}

// type LayoutProps = {
//   siteId: string
//   isSuperUser: boolean
//   username: string
//   site: Site
//   sites: Site[]
//   loadSiteList: Function
//   // logout: typeof logout
//   children: any // FIXME
//   location: any // FIXME
// }

// type LayoutState = {
//   forceVisible: boolean
//   hideNav: boolean
// }
