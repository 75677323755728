import React, { useState } from 'react'
import { Header, Icon, Input, Menu, Button } from 'semantic-ui-react'
import createEmojiRegex from 'emoji-regex'
import { Site } from '../../types'
import * as S from './styles'

interface Props {
  currentSite: Site
  sites: Site[]
  showCloseButton: boolean
  onVisibilityChange?: (visible: boolean) => unknown
  onClose: () => unknown
}

export default function SiteSwitcher(props: Props) {
  const [filterValue, setFilterValue] = useState('')
  const [showPicker, _setShowPicker] = useState(false)

  const setShowPicker = (visible: boolean) => {
    _setShowPicker(visible)
    props.onVisibilityChange && props.onVisibilityChange(visible)
  }

  const handleSiteClick = () => {
    setShowPicker(false)
    setFilterValue('')
    props.showCloseButton && props.onClose()
  }

  const sites = props.sites.sort(sortByName).filter((s) => {
    if (filterValue === '') return true
    return (
      s.name.indexOf(filterValue) > -1 || s.domain.indexOf(filterValue) > -1
    )
  })

  const showEmptyState = filterValue !== '' && sites.length === 0

  return (
    <S.SiteSwitcher isOpen={showPicker}>
      <S.SiteSwitcherContent onClick={() => setShowPicker(!showPicker)}>
        <Header size="large" inverted>
          {props.currentSite.name}
        </Header>
        <Icon name={showPicker ? 'chevron up' : 'chevron down'} inverted />
      </S.SiteSwitcherContent>

      {props.showCloseButton && (
        <Button
          inverted
          circular
          size="large"
          icon="close"
          style={{ position: 'absolute', top: 16, right: 16 }}
          onClick={props.onClose}
        />
      )}

      {showPicker && (
        <S.SitesList>
          <Menu fluid secondary inverted vertical>
            <Menu.Item>
              <Input
                autoFocus
                placeholder="Search..."
                fluid
                inverted
                transparent
                icon="search"
                iconPosition="left"
                value={filterValue}
                onChange={(e) => {
                  setFilterValue(e.currentTarget.value)
                }}
              />
            </Menu.Item>
            {showEmptyState && <NoSitesMatch value={filterValue} />}
            {sites.map((site) => (
              <S.SiteLink
                key={site.id}
                to={`/sites/${site.id}/dashboard`}
                className="item"
                onClick={handleSiteClick}
              >
                <b>{site.name}</b>
                <br />
                {site.domain}
              </S.SiteLink>
            ))}
          </Menu>
        </S.SitesList>
      )}
    </S.SiteSwitcher>
  )
}

const NoSitesMatch = ({ value }: { value: string }) => (
  <Menu.Item
    style={{
      color: '#fff',
    }}
  >
    There are no sites matching "{value}"
  </Menu.Item>
)

/**
 * Remove Emoji from a site name
 */
const emojiRegex = createEmojiRegex()
const scrubName = (name: string): string => name.replace(emojiRegex, '').trim()

const sortByName = (a: Site, b: Site) => {
  return scrubName(a.name).localeCompare(scrubName(b.name))
}
