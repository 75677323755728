import React, { useMemo } from 'react'
import { Link } from '@reach/router'
import styled from 'styled-components'
import { RouteComponentProps } from '@reach/router'
import { useSiteList, sortByName } from './Sites'
import LoadingIndicator from './LoadingIndicator'

const SiteList = styled.div`
  background: #f5f6f8;
  margin: 0 auto;
  padding-bottom: 32px;
  flex-grow: 1;
`

const Header = styled.div`
  padding: 32px;
  margin-bottom: 4em;
  background: #6435c9;
  font-size: large;
`

const SiteRow = styled(Link)`
  display: flex;
  padding: 32px;
  max-width: 500px;
  margin: auto;
  align-items: center;
  justify-content: space-between;

  :hover {
    background: linear-gradient(to bottom right, #6435c9, 70%, violet);
    // background-color: #6435c9;
    color: #fff;
  }

  :not(:last-child) {
    box-shadow: 0 1px 0 0 rgba(34, 36, 38, 0.15);
  }
`

const UIExperiment: React.FC<{}> = ({ children }) => {
  return null
  // if (process.env.NODE_ENV !== 'development') return null
  // eslint-disable-next-line no-unreachable
  return <div style={{ marginTop: 300, marginBottom: 300 }}>{children}</div>
}

interface Props extends RouteComponentProps {}

export default function SiteChooser(_: Props) {
  const { data, loading } = useSiteList()
  const sites = useMemo(() => {
    if (data && data.sites) {
      return data.sites.sort(sortByName)
    }
    return []
  }, [data])

  if (loading) return <LoadingIndicator />

  return (
    <SiteList>
      <div>
        <Header>
          <div className="ui inverted icon center aligned header">
            <img
              src={require('../pages/gouda_hero.png')}
              alt=""
              className="image"
            />
            <div className="content">Pick a site to continue&hellip;</div>
          </div>
        </Header>

        <div className="ui container">
          <h2>Your Sites</h2>
          <div className="ui three column stackable doubling grid">
            {sites.map((site) => (
              <div className="column" key={site.id}>
                <Link
                  className="ui fluid card"
                  to={`/sites/${site.id}/dashboard`}
                >
                  <div className="content">
                    <div className="header">{site.name}</div>
                    <div className="description">{site.domain}</div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>

        <UIExperiment>
          <div className="ui container">
            <div className="ui two column divided stackable grid">
              <div className="row">
                <div className="column">
                  <h2>Your Sites</h2>
                  <div className="ui secondary vertical menu">
                    {sites.map((site) => (
                      <Link
                        key={site.id}
                        to={`/sites/${site.id}/dashboard`}
                        className="item"
                      >
                        {site.name}
                      </Link>
                    ))}
                  </div>
                  {/* <p>hi!</p> */}
                </div>
                <div className="column">
                  <h2>Recent Sites</h2>
                  <div className="ui secondary vertical menu">
                    {sites
                      .reverse()
                      .slice(0, 10)
                      .map((site) => (
                        <Link
                          key={site.id}
                          to={`/sites/${site.id}/dashboard`}
                          className="item"
                        >
                          {site.name}
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </UIExperiment>

        <UIExperiment>
          {sites.map((site: any) => (
            <SiteRow to={`/sites/${site.id}/dashboard`} key={site.code}>
              <div>
                <div>{site.name}</div>
                <small>{site.domain}</small>
              </div>
              <i className="chevron right icon" />
            </SiteRow>
          ))}
        </UIExperiment>
      </div>
    </SiteList>
  )
}
