import React, { createContext, useState, useContext } from 'react'
import { post, endpoint } from './actions/_util'

type AuthenticatedUser = {
  authenticated: true
  id: string
  isSuperUser: boolean
  username: string
}
type UnknownUser = { authenticated: false }
type UserInfo = AuthenticatedUser | UnknownUser

type AuthContextType = {
  user: UserInfo
  setUser: (user: UserInfo) => Promise<unknown>
  logout: () => Promise<unknown>
}

export const AuthContext = createContext<AuthContextType>({
  user: { authenticated: false },
  setUser: () => Promise.resolve(),
  logout: () => Promise.resolve(),
})

export const AuthProvider: React.FC<{ user?: UserInfo }> = ({
  user = { authenticated: false },
  children,
}) => {
  const [_user, setUserState] = useState<UserInfo>(user)
  const setUser = (user: UserInfo) => {
    console.log('AuthProvider#setUser', user)
    setUserState(user)
    return Promise.resolve()
  }
  const logout = () => {
    setUserState({ authenticated: false })
    return post(endpoint('/logout')).then(() => true)
  }

  return (
    <AuthContext.Provider value={{ user: _user, setUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export const AuthConsumer = AuthContext.Consumer

export function useAuthInfo(): AuthContextType {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useCurrentUser must be used within a AuthProvider')
  }
  return context
}

export function useCurrentUser(): AuthenticatedUser {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useCurrentUser must be used within a AuthProvider')
  }
  if (!context.user.authenticated) {
    throw new Error('User must be authenticated')
  }
  return context.user
}
