import React, { createContext, useContext } from 'react'
import gql from 'graphql-tag'
import createEmojiRegex from 'emoji-regex'
import { useGetSitesQuery } from '../../graphql.types'

export const __GET_SITES_QUERY = gql`
  query GetSites {
    sites {
      id
      code
      domain
      name
    }
  }
`

export function useSiteList() {
  return useGetSitesQuery()
}

const emojiRegex = createEmojiRegex()
const scrubName = (name: string): string => name.replace(emojiRegex, '').trim()

type Site = {
  id: string
  name: string
  domain: string
}

type NamedSite = {
  name: string
}

export function sortByName(a: NamedSite, b: NamedSite) {
  return scrubName(a.name).localeCompare(scrubName(b.name))
}

const CurrentSiteContext = createContext<Site | undefined>(undefined)
type CurrentSiteProviderProps = { site: Site; children: React.ReactNode }

export function CurrentSiteProvider({
  site,
  children,
}: CurrentSiteProviderProps) {
  return (
    <CurrentSiteContext.Provider value={site}>
      {children}
    </CurrentSiteContext.Provider>
  )
}

export function useCurrentSite() {
  const context = useContext(CurrentSiteContext)
  if (context === undefined) {
    throw new Error('useCurrentSite must be used within a CurrentSiteProvider')
  }
  return context
}
