import { lazy } from 'react'

export const Dashboard = lazy(() =>
  import(/* webpackChunkName: "dashboard" */ './components/Dashboard')
)

export const BlogAuthorList = lazy(() =>
  import(/* webpackChunkName: "blog" */ './components/Blog/BlogAuthorList')
)

export const BlogAuthorEditor = lazy(() =>
  import(/* webpackChunkName: "blog" */ './components/Blog/BlogAuthorEditor')
)

export const BlogPostList = lazy(() =>
  import(/* webpackChunkName: "blog" */ './components/Blog/BlogPostsList')
)

export const BlogPostEditor = lazy(() =>
  import(/* webpackChunkName: "blog" */ './components/Blog/BlogPostEditor')
)

export const PageList = lazy(() =>
  import(/* webpackChunkName: "pages" */ './components/Pages/PageList')
)

export const PageEditor = lazy(() =>
  import(/* webpackChunkName: "pages" */ './components/Pages/PageEditor')
)

export const MenuList = lazy(() =>
  import(/* webpackChunkName: "menus" */ './components/MenuList')
)

export const MenuEditor = lazy(() =>
  import(/* webpackChunkName: "menus" */ './components/MenuEditor')
)

export const FoodMenuList = lazy(() =>
  import(/* webpackChunkName: "food-menus" */ './components/FoodMenuList')
)

export const FoodMenuEditor = lazy(() =>
  import(/* webpackChunkName: "food-menus" */ './components/FoodMenuEditor')
)

export const FoodMenuEditor2 = lazy(() =>
  import(
    /* webpackChunkName: "food-menus" */ './components/FoodMenus/FoodMenuEditorPage'
  )
)

export const FormList = lazy(() =>
  import(/* webpackChunkName: "forms" */ './components/Forms/FormList')
)

export const FormEditor = lazy(() =>
  import(/* webpackChunkName: "forms" */ './components/FormEditor')
)

export const FormSubmissionList = lazy(() =>
  import(
    /* webpackChunkName: "forms" */ './components/Forms/FormSubmissionList'
  )
)

export const FormSubmissionDetails = lazy(() =>
  import(
    /* webpackChunkName: "forms" */ './components/Forms/FormSubmissionDetails'
  )
)

export const OrderList = lazy(() =>
  import(/* webpackChunkName: "commerce" */ './components/Commerce/OrderList')
)

export const OrderDetails = lazy(() =>
  import(
    /* webpackChunkName: "commerce" */ './components/Commerce/OrderDetails'
  )
)

export const CommerceMain = lazy(() =>
  import(
    /* webpackChunkName: "commerce" */ './components/Commerce/CommerceMain'
  )
)

export const TemplateEditor = lazy(() =>
  import(/* webpackChunkName: "themes" */ './components/TemplateEditor')
)

export const UserList = lazy(() =>
  import(/* webpackChunkName: "usermgmt" */ './components/UserList')
)

export const UserEditor = lazy(() =>
  import(/* webpackChunkName: "usermgmt" */ './components/UserEditor')
)

export const EventList = lazy(() =>
  import(/* webpackChunkName: "eventmgmt" */ './components/Events/EventList')
)

export const EventEditor = lazy(() =>
  import(/* webpackChunkName: "eventmgmt" */ './components/Events/EventEditor')
)

export const RedirectList = lazy(() =>
  import(
    /* webpackChunkName: "redirects" */ './components/Redirects/RedirectList'
  )
)

export const SettingsPage = lazy(() =>
  import(/* webpackChunkName: "sites" */ './components/Sites/SettingsPage')
)

export const SiteList = lazy(() =>
  import(/* webpackChunkName: "mgmt-sites" */ './components/Sites/SiteListPage')
)

export const SiteEditor = lazy(() =>
  import(/* webpackChunkName: "mgmt-sites" */ './components/SiteEditor')
)

// Internal Site Stuff

export const InternalSiteRoutesList = lazy(() =>
  import(
    /* webpackChunkName: "internal" */ './components/Internal/SiteRoutesList'
  )
)

export const InternalSiteAssetsPage = lazy(() =>
  import(/* webpackChunkName: "internal" */ './components/Internal/SiteAssets')
)
